

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MetaProvider = ({title, imageUrl, description, keywords, author, robots}) => {

    console.log(window.origin)
    let url=window.origin.split("?")[0]
    let keywords_default=[
        "IT development solutions",
        "web portals development",
        "mobile app development",
        "UI/UX design services",
        "AI-embedded software",
        "digital transformation solutions",
        "innovative software development",
        "technology consulting",
        "business automation solutions",
        "software engineering expertise"
    ]
    console.log(title, imageUrl, description, keywords, author, robots)

    
    return (

        <Helmet>
            {/* Primary Meta Tags */}
            <title>{title || "Teceads Solutions : We are IT Development Experts, Excels in Web Portals, Apps, UI/UX, AI Embedded solutions developments"}</title>
            <meta name="title" content={title || "Teceads Solutions : We are IT Development Experts, Excels in Web Portals, Apps, UI/UX, AI Embedded solutions developments"} />
            <meta name="description" content={description || "Welcome to Teceads Solutions, your premier destination for IT development. Specializing in creating innovative web portals, mobile apps, and exceptional UI/UX designs, we empower businesses with cutting-edge AI-embedded solutions. Stay ahead in the digital age with our expertise in AI development, DevOps practices, and cloud hosting solutions on AWS. Transform your ideas into powerful, user-friendly software that drives success. Explore our comprehensive services in AI development, DevOps consulting, cloud hosting on AWS, and UI/UX design to elevate your business to the next level. Experience excellence with our expert team today."} />
            <meta name="keywords" content={keywords?.join(", ") || keywords_default?.join(", ")  } />
            <meta name="author" content={author||"Teceads Solutions Private Limited"} />
            <meta name="robots" content={robots||"index, follow"} />
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url || "https://teceads.com"} />
            <meta property="og:title" content={title || "Teceads Solutions : We are IT Development Experts, Excels in Web Portals, Apps, UI/UX, AI Embedded solutions developments"} />
            <meta property="og:description" content={description || "Welcome to Teceads Solutions, your premier destination for IT development. Specializing in creating innovative web portals, mobile apps, and exceptional UI/UX designs, we empower businesses with cutting-edge AI-embedded solutions. Stay ahead in the digital age with our expertise in AI development, DevOps practices, and cloud hosting solutions on AWS. Transform your ideas into powerful, user-friendly software that drives success. Explore our comprehensive services in AI development, DevOps consulting, cloud hosting on AWS, and UI/UX design to elevate your business to the next level. Experience excellence with our expert team today."} />
            <meta property="og:image" content={imageUrl||"https://teceads.com/teceads_1.png"} />
            

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={url || "https://teceads.com"}  />
            <meta property="twitter:title" content={title || "Teceads Solutions : We are IT Development Experts, Excels in Web Portals, Apps, UI/UX, AI Embedded solutions developments"} />
            <meta property="twitter:description" content={description || "Welcome to Teceads Solutions, your premier destination for IT development. Specializing in creating innovative web portals, mobile apps, and exceptional UI/UX designs, we empower businesses with cutting-edge AI-embedded solutions. Stay ahead in the digital age with our expertise in AI development, DevOps practices, and cloud hosting solutions on AWS. Transform your ideas into powerful, user-friendly software that drives success. Explore our comprehensive services in AI development, DevOps consulting, cloud hosting on AWS, and UI/UX design to elevate your business to the next level. Experience excellence with our expert team today."} />
            <meta property="twitter:image" content={imageUrl||"https://teceads.com/teceads_1.png"} />

            {/* Additional Meta Tags */}
            <link rel="canonical" href={url || "https://teceads.com"} />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="application-name" content={title || "Teceads Solutions : We are IT Development Experts, Excels in Web Portals, Apps, UI/UX, AI Embedded solutions developments"} />
            <meta name="apple-mobile-web-app-title" content={title || "Teceads Solutions : We are IT Development Experts, Excels in Web Portals, Apps, UI/UX, AI Embedded solutions developments"} />
            <meta name="msapplication-starturl" content="/" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content="https://www.teceads.com/android-chrome-192x192.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="https://www.teceads.com/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="https://www.teceads.com/favicon-16x16.png" />
        </Helmet>

    )
}
export default MetaProvider;