import React, { Component } from "react";
import { Link } from "react-router-dom";

const HeaderMenu = ({ toggleFunc }) => {
  const handleLinkClick = () => {
      toggleFunc();
  };
    return (
      <>
        <ul className="nav navbar-nav">
          <li>
            <Link to={"/"}  
             onClick={handleLinkClick}
            >
              <span className="ti-home"></span>{" "}
              {/*<i className="fa fa-chevron-down"></i>*/}
            </Link>
          </li>
          <li>
            <Link to={"/about-us"} 
             onClick={handleLinkClick}
            >
               
              About Us {/*<i className="fa fa-chevron-down"></i>*/}
            </Link>
          </li>
          <li>
            <Link to={"/our-services"}
               onClick={handleLinkClick}
            >
              {" "}
              Services {/*<i className="fa fa-chevron-down"></i>*/}
            </Link>
          </li>
          <li>
            <Link to={"/our-products"}
              onClick={handleLinkClick}
            >
              Products{/*<i className="fa fa-chevron-down"></i> */}
            </Link>
          </li>
          <li>
            <Link to={"/our-portfolio"}
              onClick={handleLinkClick}
            >
               Our Portfolio 
            </Link>
          </li>

          <li>
            <Link to={"/blogs"}
              onClick={handleLinkClick}
             >
              Blogs 
            </Link>
          </li>
          <li>
            <Link to={"/gallery"} 
          onClick={handleLinkClick}
            >Gallery</Link>
          </li>
          <li>
            <Link to={"/team"} 
            
             onClick={handleLinkClick}
            >Team</Link>
          </li>
          <li>
            <Link to={"/contact-us"} 
             onClick={handleLinkClick}
            >Contact Us</Link>{" "}
          </li>
          <li>
            <Link to={"#"}>
              More <i className="fa fa-chevron-down"></i>{" "}
            </Link>
            <ul className="sub-menu left">
              <li>
                <Link to={"/expertise"} className="dez-page"   onClick={handleLinkClick}>
                  Expertise
                </Link>
              </li>
              <li>
                <Link to={"/project-management"} className="dez-page"   onClick={handleLinkClick}>
                  Project Management
                </Link>
              </li>
              <li>
                <Link to={"/faqs"} className="dez-page"   onClick={handleLinkClick}>
                  FAQ's
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </>
    );
  }
export default HeaderMenu;
