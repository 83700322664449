import React, { Component, useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import axios from 'axios';
import { apiurl } from '../../api handler/apiurl';
//Images
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'



const TestimonialCarousel = React.memo(() => {

	const [ourtstmonialdata, setourtestimonialdata] = useState("")
	const [ourtestimonilasliderdata, setourtestimonialsliderdata] = useState([])
	const FetchourTestmonialCoursel = async () => {
		const res = await axios({
			method: "POST",
			url: `${apiurl}/home/testimonialsSlider/getAll/getAllTestimonialsSlider`,
			headers: {
				'Accept': 'application/json',
				'content-Type': "application/json"
			}
		})

		setourtestimonialsliderdata(res?.data?.data)

	}
	const FetchourTestmonial = async () => {
        const res = await axios({
            method: "POST",
            url: `${apiurl}home/testimonialTop/getAll/getAllTestimonialsTop`,
            headers: {
                'Accept': 'application/json',
                'content-Type': "application/json"
            }
        })

        setourtestimonialdata(res?.data?.data[0])
    }

	const fun = async () => {
		await FetchourTestmonial()
		await FetchourTestmonialCoursel()
	
	}


	var settings = {
		arrows: false,
		slidesToShow: 3,
		infinite: true,
		dots: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};


    const [isLoaded__,setIsloaded__]=useState(false)
    const componentRef=useRef()
    useEffect(() => {
        
        const observer=new IntersectionObserver(entries=>{
            entries.forEach(entry=>{

                if(entry.isIntersecting && !isLoaded__){
                    fun()
                    setIsloaded__(true)
                }

            })
        },{threshold:0.1})
        if(componentRef.current){
            observer.observe(componentRef.current)
        }

        return ()=>observer.disconnect();

    }, [isLoaded__])

	return (<div ref={componentRef} className="container">
		<div className="section-head text-center">
			<h2 className="box-title m-tb0">{ourtstmonialdata?.heading ||<Skeleton height={50} width={300} baseColor='#8FBC8F'/>}<span className="bg-primary"></span></h2>
			<p> {ourtstmonialdata?.description || <Skeleton count={2} height={20} baseColor='#8FBC8F'/>} </p>
		</div>
		<div className="section-content m-b30 ">
			<Slider className="testimonial-box center-active-owl item-center owl-loaded owl-carousel owl-none  owl-dots-black-full " {...settings}>
				{ourtestimonilasliderdata?.length===0?([...Array(3)]?.map((_, index) => (
						<div className="item" key={index}>
							<div className="testimonial-8">
								{
									index % 2 === 0 ? (
										<>
											<div className="testimonial-text">
												<p><Skeleton height={135} baseColor='#8FBC8F' /></p>
											</div>
											<div className="testimonial-detail clearfix">
												<div className="testimonial-pic radius shadow">
													<Skeleton  height={65} baseColor='#8FBC8F' circle/>
													
												</div>
												<h5 className="testimonial-name m-t0 m-b5"><Skeleton baseColor='#8FBC8F' width={150}/></h5>
												<span><Skeleton baseColor='#8FBC8F' width={150}/></span>
											</div>
										</>



									) : (
										<>
											<div className="testimonial-text ">
												<div className="video-testimonial">
													{/* <img src={item?.image} alt="" /> */}
													<Skeleton baseColor='#8FBC8F' height={135}/>
													
												</div>
												

											</div>
											<div className="testimonial-detail clearfix">
												<div className="testimonial-pic radius shadow">
													<Skeleton baseColor='#8FBC8F' height={65} circle/>
													
												</div>
												<h5 className="testimonial-name m-t0 m-b5"><Skeleton baseColor='#8FBC8F' width={150}/></h5>
												<span><Skeleton baseColor='#8FBC8F' width={150}/></span>
											</div>
											
										</>
									)
								}
							</div>
						</div>

					))):( ourtestimonilasliderdata?.map((item, index) => (
						<div className="item" key={item?._id}>
							<div className="testimonial-8">
								{
									index % 2 === 0 ? (
										<>
											<div className="testimonial-text">
												<p>{item?.description}</p>
											</div>
											<div className="testimonial-detail clearfix">
												<div className="testimonial-pic radius shadow">
													<img src={item?.avatar} width="100" height="100" alt="" />
												</div>
												<h5 className="testimonial-name m-t0 m-b5">{item?.clientName}</h5>
												<span>{item?.designation}</span>
											</div>
										</>



									) : (
										<>
											<div className="">
												<div className="video-testimonial">
													<img src={item?.image} alt="" />
													<div className="">
														<a href={`${item?.url}`} className="" title="Image Title Come Here"></a>
													</div>
												</div>

											</div>
											<div className="testimonial-detail clearfix">
												<div className="testimonial-pic radius shadow">
													<img src={item?.avatar} width="100" height="100" alt="" />
												</div>
												<h5 className="testimonial-name m-t0 m-b5">{item?.clientName}</h5>
												<span>{item?.designation}</span>
											</div>
										</>
									)
								}
							</div>
						</div>

					)))}
				
					
				






			</Slider >
		</div>
	</div>
	)
})



export default TestimonialCarousel;