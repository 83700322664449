import React, { useEffect, useState } from 'react';
import Markup from './markup/Markup';

import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css';
import './plugins/slick/slick.min.css';
import './plugins/slick/slick-theme.min.css';
import 'react-modal-video/css/modal-video.min.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastProvider } from 'react-toast-notifications';
// import  HelmetProvider  from 'react-helmet-async';


function App() {

  // console.log=()=>{}
  // console.error=()=>{}
  // console.warn=()=>{}
  // console.debug=()=>{}
  const helmetContext = {};

  return (
    // <HelmetProvider context={helmetContext}>
    <div className="App">
      <ToastProvider autoDismiss={true}>
        <Markup />
      </ToastProvider>
    </div>

    // </HelmetProvider>
  );
}

export default App;
