import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import bgmap from './../../images/background/bg-map.jpg';
import axios from 'axios'
import { apiurl } from '../../api handler/apiurl';
import '../../css/Loader.css'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { useToasts } from 'react-toast-notifications';
import { UploadToAWS } from '../../uploadfile/uploadpdf';
import ExpertiseBelt from './expertisebelt';
import { useLocation } from 'react-router-dom'
import Pagination from '../../utils/pagination';
import MetaProvider from '../Helmet';
const PageTitle = lazy(() => import("../Layout/PageTitle"))
const FormStyle = lazy(() => import("../Element/FormStyle"))
const TestimonialCarousel = lazy(() => import("../Element/TestimonialCarousel"))
// const Productbelt = lazy(() => import("../Element/productbelt"))

function Expertise() {
    const history = useHistory();
    const [fetchExpertiseTopdata, setfettchExpertiseTop] = useState("")
    const [fetchExpertisecardData, setFetchExpertiseCardData] = useState([])
    const [Name, setName] = useState("")
    const [phone, Setphone] = useState("")
    const [Email, setEmail] = useState("")
    const [fileinfo, setfileinfo] = useState("")
    const [idea, setidea] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedPageSize, setSelectedPageSize] = useState(8)
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [fileName, setFileName] = useState("");
    const [error, setError] = useState({})
    const [searchText, setSearchText] = useState('');
    const { addToast } = useToasts();
    const Validate = () => {
        let error = {}
        if (!Name) {
            error.Name = "Name is required"
        }
        if (!phone && !Email) {
            error.PhoneOrEmail = "Either Phone or Email is required";
        }
        else if (!Email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
            error.PhoneOrEmail = "It is an Invalid Email"
        }
        if (!fileinfo) {
            error.fileinfo = "File is required";
        }
        if (!idea) {
            error.idea = "This field is required"
        }

        setError(error)

        return error
    }
    let searchQuery
    const location = useLocation()
    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        searchQuery = searchParams.get('search');

        setSearchText(searchQuery);

    }, []);
    const fetchExpertiseTop = async () => {
        try {
            const res = await axios({
                method: "POST",
                url: `${apiurl}/more/expertiseTop/getAll/getAllExpertiseTop`,
                headers: {
                    'Accept': 'application/json',
                    'content-Type': "application/json"
                },

            }
            )

            setfettchExpertiseTop(res?.data?.data[0])

        } catch (error) {
            console.log(error)

        }

    }
    const fetchExpertiseCard = async () => {
        try {
            const res = await axios({
                method: "POST",
                url: `${apiurl}/more/expertiseCard/getAll/getAllExpertiseCard`,
                headers: {
                    'Accept': 'application/json',
                    'content-Type': "application/json"
                },
                data: {
                    searchText: searchQuery,
                    pageSize: selectedPageSize,
                    currentPage: currentPage
                }

            })
            if (res?.data?.code === 200) {

                setFetchExpertiseCardData(res?.data?.data)
                setTotalItemCount(res?.data?.totalCount)
                setTotalPage(Math.ceil(res?.data?.totalCount / selectedPageSize))
            }

        } catch (error) {
            console.log(error)
        }



    }
    const AddClientResponse = async () => {
        const error = Validate()
        if (Object.keys(error).length > 0) {
            return false
        }
        try {
            const res = await axios({
                method: "POST",
                url: `${apiurl}/contactUs/clientres/create/clientresponse`,
                headers: {
                    'Accept': 'application/json',
                    'content-Type': "application/json"
                },
                data: {
                    Name: Name,
                    Phone: phone,
                    EmailId: Email,
                    File: fileinfo,
                    idea: idea


                }
            })
            if (res?.data?.code == 201) {
                setName("")
                Setphone("")
                setFileName("")
                setEmail("")
                setfileinfo("")
                setidea("")
                addToast('Response Added Successfully', { appearance: 'success' });


            }
        } catch (error) {
            addToast('Something Went to wrong please try again!', { appearance: 'danger' });

            console.error("Error adding client response:", error);
        }
    };
    const handleFileChange = async (event) => {
        try {
            event.preventDefault()
            let file = event.target.files[0];
            if (file) {
                setFileName(file.name)
            }

            const responseUrl = await UploadToAWS(file);


            setfileinfo(responseUrl)

        } catch (error) {
            console.log(error)
        }

    }
    const fun = async () => {

        await fetchExpertiseTop()
        // await fetchExpertiseCard()
    }
    useEffect(() => {

        fetchExpertiseCard(selectedPageSize, currentPage)
    }, [currentPage])




    const [isLoaded__, setIsloaded__] = useState(false)
    const componentRef = useRef()
    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                if (entry.isIntersecting && !isLoaded__) {
                    fun()
                    setIsloaded__(true)
                }

            })
        }, { threshold: 0.1 })
        if (componentRef.current) {
            observer.observe(componentRef.current)
        }

        return () => observer.disconnect();

    }, [isLoaded__])


    return (
        <div ref={componentRef} className="page-wraper">
                    <MetaProvider title={fetchExpertiseTopdata?.heading } description={fetchExpertiseTopdata?.desc }/>

            <Suspense fallback={<div className="loader-container">
                <div className="loader"></div>
            </div>}>



                <div className="page-content bg-white">
                    {/* inner page banner */}
                    {fetchExpertiseTopdata?.image ? (
                        <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: `url( ${fetchExpertiseTopdata?.image} )` }}>
                            <PageTitle motherMenu='Expertise' activeMenu='Expertise' />
                        </div>
                    ) : (
                        <Skeleton height={300} baseColor='#556B2F' />
                    )}
                    {/* inner page banner END */}
                    <div className="content-block">
                        {/* About Us */}
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <div className="section-head text-black">
                                            <h4 className="text-gray-dark font-weight-300 m-b10">{fetchExpertiseTopdata?.heading || <Skeleton width={100} />}</h4>
                                            <h2 className="box-title m-tb0">{fetchExpertiseTopdata?.subHeading || <Skeleton width={500} />}<span className="bg-primary"></span></h2>
                                            <p>{fetchExpertiseTopdata?.desc || <Skeleton count={3} />}</p>
                                        </div>
                                        <div className="row serv">
                                            {fetchExpertisecardData.length === 0 ? (
                                                [...Array(6)].map((_, index) => (
                                                    <div className="col-lg-4 col-md-6 col-sm-6 m-b30" key={index}>
                                                        <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
                                                            <div className="icon-content">
                                                                <Skeleton height={200} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                fetchExpertisecardData?.map((data, index) => (
                                                    <div className=" col-lg-6 col-md-4 col-sm-4 m-b30" key={index}>
                                                        <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
                                                            <div className="icon-lg m-b20">
                                                                <Link to={"#"} className="icon-cell">
                                                                    <img src={data?.image} alt="" />
                                                                </Link>
                                                            </div>
                                                            <div className="icon-content">
                                                                <h5 className="dlab-tilte text-uppercase">
                                                                    <span style={{ cursor: 'pointer' }} >
                                                                        {data?.heading}
                                                                    </span>
                                                                </h5>
                                                                <p>{data?.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPage={totalPage}
                                            onChangePage={i => setCurrentPage(i)}
                                        />
                                    </div>

                                    <div className="col-lg-4 col-md-5 sticky-top">
                                        <form className="inquiry-form inner dzForm" action="script/contact.php">
                                            <div className="dzFormMsg"></div>
                                            <h3 className="box-title m-t0 m-b10">Let's Convert Your Idea into Reality <span className="bg-primary"></span></h3>
                                            <p>we're here to create the future. Your ideas, your vision, they're the fuel for innovation, and we're ready to turn them into reality</p>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                            <input name="dzName" type="text" className="form-control" placeholder="Name" value={Name} onChange={(e) => { setName(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    {error.Name && (<p className="text-danger mt-2">{error.Name}</p>)}
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                            <input name="dzOther[Phone]" type="text" className="form-control" placeholder="Phone" value={phone} onChange={(e) => { Setphone(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                            <input name="dzEmail" type="email" className="form-control" placeholder="Your Email Id" value={Email} onChange={(e) => setEmail(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    {error?.PhoneOrEmail && (<p className="text-danger mt-2">{error.PhoneOrEmail}</p>)}
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-check-box text-primary"></i></span>
                                                            <label className="form-control" style={{ cursor: 'pointer', padding: '10px', borderRadius: '5px', backgroundColor: 'white' }}>
                                                                {fileName ? (fileName) : ("Upload  Pdf File")}
                                                                <input
                                                                    name="dzOther[Subject]"
                                                                    type="file"
                                                                    accept="doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf"
                                                                    required
                                                                    multiple
                                                                    className="form-control"
                                                                    placeholder="Upload File"
                                                                    onChange={handleFileChange}
                                                                    style={{
                                                                        display: 'none'
                                                                    }}
                                                                />
                                                            </label>
                                                        </div>
                                                        {error.fileinfo && (<p className="text-danger mt-2">{error.fileinfo}</p>)}
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                            <textarea name="dzMessage" rows="4" className="form-control" value={idea} onChange={(e) => setidea(e.target.value)} placeholder="Tell us about your project or idea"></textarea>
                                                        </div>
                                                    </div>
                                                    {error.idea && <p className="text-danger">{error.idea}</p>}
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                            <input className="form-control d-none" data-recaptcha="true" required data-error="Please complete the Captcha" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <button name="submit" type="submit" value="Submit" className="site-button button-lg" onClick={AddClientResponse}> <span>Send Message</span> </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Our Services */}
                        {/* Why Chose Us */}
                        {/* <SectionCounter /> */}
                        <ExpertiseBelt />
                        {/* Why Chose Us End */}
                        {/* Testimonials */}
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgmap + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">

                                <div className="section-content m-b30 mt-5">
                                    <TestimonialCarousel />
                                </div>
                            </div>
                        </div>
                        {/* Testimonials END */}
                        {/* Get in touch */}

                        <FormStyle />

                        {/* Get in touch */}
                    </div>
                    {/* contact area END */}
                </div>


            </Suspense>

        </div>
    );
}

export default Expertise;
