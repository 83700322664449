import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SimpleReactLightbox, {
    SRLWrapper,
    useLightbox,
} from "simple-react-lightbox";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { apiurl } from "../../api handler/apiurl";
import pic1 from "./../../images/project/pic1.jpg";
import pic2 from "./../../images/project/pic2.jpg";
import pic3 from "./../../images/project/pic3.jpg";
import pic4 from "./../../images/project/pic4.jpg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const contentBlog = [
    { images: pic1 },
    { images: pic2 },
    { images: pic3 },
    { images: pic4 },
    { images: pic2 },
];

//Light Gallery on icon click
const Iconimage = (props) => {
    const { openLightbox } = useLightbox();
    return (
        <Link
            to={"#"}
            onClick={() => openLightbox(props.imageToOpen)}
            className="mfp-link"
            title="Title Come Here"
        >
            <i className="ti-fullscreen icon-bx-xs"></i>
        </Link>
    );
};

const PortfolioCarousel = React.memo(() => {
    const [ourportfolioslider, setourportfolioslider] = useState([]);
    const [ourportfolio, setourportfolio] = useState("");
    const history = useHistory();

    var settings = {
        arrows: false,
        slidesToShow: 4,
        speed: 3000,
        navSpeed: 3000,
        infinite: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const ourportfolioSlider = async () => {
        try {
            const res = await axios({
                method: "POST",
                url: `${apiurl}/Portfolio/Portfoiloimage/getAll/getAllportfolioimage`,
                headers: {
                    Accept: "application/json",
                    "content-Type": "application/json",
                },
            });

            setourportfolioslider(res?.data?.data);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchOurportfolio = async () => {
        try {
            const res = await axios({
                method: "POST",
                url: `${apiurl}home/portfolioTop/getAll/getAllPortfolioTop`,
                headers: {
                    Accept: "application/json",
                    "content-Type": "application/json",
                },
            });
            setourportfolio(res?.data?.data[0]);
        }
        catch (error) {
            console.log(error)
        }

    };
    const fun = async () => {
        await fetchOurportfolio();
        await ourportfolioSlider();
    };

    const [isLoaded__, setIsloaded__] = useState(false);
    const componentRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !isLoaded__) {
                        fun();
                        setIsloaded__(true);
                    }
                });
            },
            { threshold: 0.1 }
        );
        if (componentRef.current) {
            observer.observe(componentRef.current);
        }

        return () => observer.disconnect();
    }, [isLoaded__]);

    const handelviewdetails = (data) => {
        const url = `/portfolio-details/${data.url.replace("/", "-")}`;
        history.push(url);
    };
    return (
        <div ref={componentRef} className="container-fluid">
            <div className="section-head text-center">
                <h4 className="text-gray-dark m-b10">
                    {ourportfolio?.heading || <Skeleton width={50} />}
                </h4>
                <h2 className="box-title m-tb0">
                    {ourportfolio?.SubHeading || <Skeleton minWidth={5} />}
                </h2>
                <span className="bg-primary"></span>
                <h5>{ourportfolio?.description || <Skeleton width={700} />}</h5>
            </div>
            <SimpleReactLightbox>
                <SRLWrapper>
                    <Slider
                        className="img-carousel-content owl-carousel text-center text-white owl-none"
                        {...settings}
                    >
                        {ourportfolioslider.length === 0
                            ? // Render Skeleton components while data is being fetched

                            [...Array(5)].map((_, index) => (
                                <div className="item p-3" key={index}>
                                    <div className="dlab-box portfolio-box">
                                        <div className="dlab-media dlab-img-effect dlab-img-overlay1">
                                            <Skeleton height={200} width={310} />
                                        </div>
                                    </div>
                                </div>
                            ))
                            : ourportfolioslider.map((item, index) => (
                                <div className="item p-3" key={index}>
                                    <div className="dlab-box portfolio-box">
                                        <div className="dlab-media dlab-img-effect dlab-img-overlay1">
                                            <img
                                                src={item.image}
                                                alt=""
                                                style={{ border: "1px solid black", height: "240px", width: "500px", objectFit: "fill" }}
                                            />
                                            <div className="dlab-info-has p-a15 bg-primary">
                                                {Object.keys(item).length > 10 ? (
                                                    <button
                                                        className="site-button outline radius-xl white"
                                                        onClick={() => {
                                                            handelviewdetails(item);
                                                        }}
                                                    >
                                                        View Details
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="overlay-bx">
                                                <div className="overlay-icon text-white">
                                                    <h5>{item?.heading}</h5>
                                                    <p className="m-b10">{item?.description}</p>
                                                    {/* Placeholder for icons or links */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </Slider>
                </SRLWrapper>
            </SimpleReactLightbox>
        </div>
    );
});

export default PortfolioCarousel;
