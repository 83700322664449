import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import "../../../node_modules/bootstrap/dist/css/bootstrap.css"
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { UploadToAWS } from "../../uploadfile/uploadpdf";
import { apiurl } from "../../api handler/apiurl";
import { ButtonGroup } from "react-bootstrap";
function ContactusModal({ handleClose, show }) {
    const [Name, setName] = useState("");
    const [phone, Setphone] = useState("");
    const [Email, setEmail] = useState("");
    const [fileinfo, setfileinfo] = useState("");
    const [idea, setidea] = useState("");
    const [error, setError] = useState({});
    const [fileName, setFileName] = useState("");
    const { addToast } = useToasts();

    const Validate = () => {
        let error = {};
        if (!Name) {
            error.Name = "Name is required";
        }
        if (!phone && !Email) {
            error.PhoneOrEmail = "Either Phone or Email is required";
        } else if (!Email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
            error.PhoneOrEmail = "It is an Invalid Email";
        }
        if (!fileinfo) {
            error.fileinfo = "File is required";
        }
        if (!idea) {
            error.idea = "This field is required";
        }

        setError(error);

        return error;
    };
    const AddClientresponse = async () => {
        const error = Validate();
        if (Object.keys(error).length > 0) {
            return false;
        }
        try {
            const res = await axios({
                method: "POST",
                url: `${apiurl}/contactUs/clientres/create/clientresponse`,
                headers: {
                    Accept: "application/json",
                    "content-Type": "application/json",
                },
                data: {
                    Name: Name,
                    Phone: phone,
                    EmailId: Email,
                    File: fileinfo,
                    idea: idea,
                },
            });

            if (res?.data?.code === 201) {
                setName("");
                Setphone("");
                setEmail("");
                setfileinfo("");
                setFileName("");
                setidea("");
                handleClose();
                addToast("Saved Successfully", { appearance: "success" });
            }
        } catch (error) {
            addToast("SomethingWent To wrong Please try again!", {
                appearance: "danger",
            });
            console.error("Error adding client response:", error);
        }
    };
    const resetField = () => {
        setName("");
        Setphone("");
        setEmail("");
        setfileinfo("");
        setFileName("");
        setidea("");
        setError({});
    };

    const handelfileChange = async (event) => {
        try {
            event.preventDefault();
            let file = event.target.files[0];
            if (file) {
                setFileName(file.name);
            }

            const responseUrl = await UploadToAWS(file);

            setfileinfo(responseUrl);

        } catch (error) {
            console.log(error)
        }

    };
    return (
        <Modal
            onClosed={resetField}
            size="lg"
            centered
            show={show}
            onHide={handleClose}
            style={{ borderRadius: "10px" }}
        >
            <form className="inquiry-form  dzForm container">
                <h3 className="box-title m-t0 m-b10">
                    Let's Convert Your Idea into Reality{" "}
                    <span className="bg-primary"></span>
                </h3>
                <p>
                    we're here to create the future. Your ideas, your vision, they're the
                    fuel for innovation, and we're ready to turn them into reality
                </p>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    <i className="ti-user text-primary"></i>
                                </span>
                                <input
                                    name="dzName"
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder="Name"
                                    value={Name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                            {error.Name && <p className="text-danger mt-2">{error.Name}</p>}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    <i className="ti-mobile text-primary"></i>
                                </span>
                                <input
                                    name="dzOther[Phone]"
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder="Phone"
                                    value={phone}
                                    onChange={(e) => {
                                        Setphone(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    <i className="ti-email text-primary"></i>
                                </span>
                                <input
                                    name="dzEmail"
                                    type="email"
                                    className="form-control"
                                    required
                                    placeholder="Your Email Id"
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    {error?.PhoneOrEmail && (
                        <p className="text-danger mt-1 ms-3">{error.PhoneOrEmail}</p>
                    )}
                    {/* <div className="col-lg-6">
									<div className="form-group">
										<div className="input-group">
											<span className="input-group-addon"><i className="ti-check-box text-primary"></i></span>
											<input name="dzOther[Subject]" type="text" required className="form-control" placeholder="Upload File" />
										</div>
									</div>
								</div> */}
                    <div className="col-lg-12">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    <i className="ti-file text-primary"></i>
                                </span>
                                <label
                                    className="form-control"
                                    style={{
                                        cursor: "pointer",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        backgroundColor: "white",
                                    }}
                                >
                                    {fileName ? fileName : "Upload  Pdf File"}
                                    <input
                                        name="dzOther[Subject]"
                                        type="file"
                                        accept="application/pdf"
                                        required
                                        multiple
                                        className="form-control"
                                        placeholder="Upload File"
                                        onChange={handelfileChange}
                                        style={{
                                            display: "none",
                                        }}
                                    />
                                </label>
                            </div>
                            {error.fileinfo && (
                                <p className="text-danger mt-2">{error.fileinfo}</p>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <div className="input-group">
                                <span className="input-group-addon">
                                    <i className="ti-agenda text-primary"></i>
                                </span>
                                <textarea
                                    name="dzMessage"
                                    rows="4"
                                    className="form-control"
                                    required
                                    placeholder="Tell us about your project or idea"
                                    value={idea}
                                    onChange={(e) => {
                                        setidea(e.target.value);
                                    }}
                                ></textarea>
                            </div>
                        </div>
                        {error.idea && <p className="text-danger">{error.idea}</p>}
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <div className="input-group">
                                <div
                                    className="g-recaptcha"
                                    data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                    data-callback="verifyRecaptchaCallback"
                                    data-expired-callback="expiredRecaptchaCallback"
                                ></div>
                                <input
                                    className="form-control d-none"
                                    data-recaptcha="true"
                                    required
                                    data-error="Please complete the Captcha"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group text-right">
                            <Button variant="danger" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="success"
                                className="ml-3"
                                onClick={AddClientresponse}
                            >
                                send Message
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
}

export default ContactusModal;
