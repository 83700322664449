import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'





function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className="owl-nav">
            <div className="owl-next flaticon-right-arrow" onClick={onClick} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="owl-nav">
            <div className=" owl-prev flaticon-left-arrow" onClick={onClick} style={{ zIndex: 1 }} />
        </div>
    );
}

const ClientCarousel = ({ allprojectdetail }) => {
    const [clientImg, setClientImg] = useState(allprojectdetail?.image2);

    useEffect(() => {
        setClientImg(allprojectdetail?.image2);
    }, [allprojectdetail]);

    const responsiveSettings = [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: Math.min(4)
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: Math.min(3)
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: Math.min(2)
            }
        },
        {
            breakpoint: 591,
            settings: {
                slidesToShow: Math.min(1)
            }
        },
        {
            breakpoint: 360,
            settings: {
                slidesToShow: 1
            }
        }
    ];

    const settings = {
        arrows: true,
        slidesToShow: Math.min(4),
        infinite: true,
        autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: responsiveSettings
    };

    return (
        <Slider className="client-logo-carousel owl-carousel owl-btn-center-lr owl-btn-3" {...settings}>
           {!clientImg ? (
    [...Array(6)].map((_, index) => (
        <div className="item p-3" key={index}>
            <div className="br-col-b1 border-1">
                <Skeleton height={100} />
            </div>
        </div>
    ))
) : (
    clientImg?.map((item, index) => (
        <div className="item p-3" key={index}>
            <div className="br-col-b1 border-1">
                <img src={item?.img2url} alt="" />
            </div>
        </div>
    ))
)}


        </Slider>
    );
};


export default ClientCarousel;
