import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";
import Header from "./../../Layout/Header1";
import Footer from "./../../Layout/Footer1";
import PageTitle from "./../../Layout/PageTitle";
import axios from "axios";
import { apiurl } from "../../../api handler/apiurl";
import "../../../css/Loader.css";
import { useLocation } from "react-router-dom";
import Pagination from "../../../utils/pagination";
import { useHistory } from "react-router-dom";

import {
  box1,
  box2,
  box3,
  box4,
  box5,
  box6,
  box7,
  box8,
  box9,
  box10,
  box11,
  box12,
} from "./ProtfolioFullWidth";
import Masonry from 'react-masonry-component';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MetaProvider from "../../Helmet";

const imageBlog = [
  { Large: box1 },
  { Large: box2 },
  { Large: box3 },
  { Large: box4 },
  { Large: box5 },
  { Large: box6 },
  { Large: box7 },
  { Large: box8 },
  { Large: box9 },
  { Large: box11 },
  { Large: box10 },
  { Large: box12 },
];

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
//Light Gallery on icon click
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      to={"#"}
      onClick={() => openLightbox(props.imageToOpen)}
      className="mfp-link portfolio-fullscreen"
    >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  );
};

const PortfolioGrid4 = () => {
  const [ourportfolioslider, setourportfoliosliderdata] = useState([]);
  const [ourportfoltopdata, setourportfoliotopdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(4);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  let searchQuery;
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchQuery = searchParams.get("search");

    setSearchText(searchQuery);
  }, []);
  const Fetchourportfoliodata = async (selectedPageSize, currentPage) => {
    const res = await axios({
      method: "POST",
      url: `${apiurl}/Portfolio/Portfoiloimage/getAll/getAllportfolioimage`,
      headers: {
        Accept: "application/json",
        "content-Type": "application/json",
      },
      data: {
        pageSize: selectedPageSize,
        currentPage: currentPage,
        searchText: searchQuery,
      },
    });
    if (res?.data?.code === 200) {
      setourportfoliosliderdata(res?.data?.data);
      setTotalItemCount(res?.data?.totalCount);
      setTotalPage(Math.ceil(res?.data?.totalCount / selectedPageSize));
    }
  };
  const Fetchourportfoliotopdata = async () => {
    const res = await axios({
      method: "POST",
      url: `${apiurl}/Portfolio/portfoliohead/getall/getallportfoliohead`,
      headers: {
        Accept: "application/json",
        "content-Type": "application/json",
      },
    });

    setourportfoliotopdata(res?.data?.data);
  };

  useEffect(() => {
    Fetchourportfoliodata(selectedPageSize, currentPage);
  }, [currentPage]);
  const fun = async () => {
    await Fetchourportfoliotopdata();
  };
  const [isLoaded__, setIsloaded__] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded__) {
            fun();
            setIsloaded__(true);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded__]);

  return (
    <div ref={componentRef} className="page-wraper">
      <MetaProvider title={"Our Portfolio : Teceads Solutions"}/>

      <div className="page-content bg-white">
        {ourportfoltopdata.length === 0 ? (
          <Skeleton height={500} baseColor="#556B2F" />
        ) : (
          ourportfoltopdata?.map((item, index) => {
            return (
              <div
                key={index}
                className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt"
                style={{ backgroundImage: `url(${item?.image})` }}
              >
                <PageTitle
                  motherMenu="Our Portfolio"
                  activeMenu="Our Portfolio"
                />
              </div>
            );
          })
        )}

        {/*  Section-1 Start  */}
        <div className="content-block">
          <div className="section-full content-inner-2 portfolio text-uppercase">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  {ourportfoltopdata.length === 0
                    ? [...Array(1)].map((item, index) => {
                      return (
                        <div className="sticky-top" key={index}>
                          <h3>
                            <Skeleton />
                          </h3>
                          <ul className="list-check primary">
                            <li>
                              <Skeleton />
                            </li>
                            <li>
                              <Skeleton />
                            </li>
                            <li>
                              <Skeleton />
                            </li>
                            <li>
                              <Skeleton />
                            </li>
                          </ul>
                          <h3>
                            <Skeleton />
                          </h3>
                          <ul className="list-check primary">
                            <li>
                              <Skeleton />
                            </li>
                            <li>
                              <Skeleton />
                            </li>
                            <li>
                              <Skeleton />
                            </li>
                            <li>
                              <Skeleton />
                            </li>
                          </ul>
                        </div>
                      );
                    })
                    : // Render actual data
                    ourportfoltopdata.map((item, index) => {
                      return (
                        <div className="sticky-top" key={index}>
                          <h3>{item.portfolioheading1}</h3>
                          <ul className="list-check primary">
                            {item.ourportfolioheading1.map((data, idx) => (
                              <li key={idx}>{data.Heading1Points}</li>
                            ))}
                          </ul>
                          <h3>{item.portfolioheading2}</h3>
                          <ul className="list-check primary">
                            {item.ourportfolioheading2.map((data, idx) => (
                              <li key={idx}>{data.Heading2Points}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    })}
                </div>

                <div className="col-md-9">
                  <GalleryGrid
                    ourportfolioslider={ourportfolioslider}
                    currentPage={currentPage}
                    totalPage={totalPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GalleryGrid = React.memo(
  ({ ourportfolioslider, currentPage, totalPage, setCurrentPage }) => {
    const history = useHistory();

    const handleViewDeatils = (data) => {
      const url = `/portfolio-details/${data.url.replace("/", "-")}`;
      history.push(url);
    };

    return (
      <>
        <SimpleReactLightbox>
          <SRLWrapper>
            <div className="clearfix">
              <ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery port-style1">
                <Masonry
                  className={'my-gallery-class'} // default ''
                  options={masonryOptions} // default {}
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                  imagesLoadedOptions={imagesLoadedOptions} // default {}
                >
                  {ourportfolioslider.length === 0
                    ? [...Array(4)].map((_, index) => (
                      <li
                        className="web design card-container col-lg-6 col-md-6 col-sm-6 p-a0 "
                        key={index}
                      >
                        <div className="dlab-box dlab-gallery-box">
                          <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                            <Skeleton height={300}  width={600}/>
                            <div className="overlay-bx">
                              <div className="overlay-icon align-b text-white text-left">
                                <div className="text-white text-left port-box">
                                  <Iconimage />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                    : ourportfolioslider?.map((item, index) => (

                      <li
                        className="web design card-container col-lg-6 col-md-6 col-sm-6 p-a0   "
                        key={index}
                      >
                        <div className="dlab-box dlab-gallery-box">
                          <div className="dlab-media dlab-img-effect dlab-img-overlay1">
                            <img src={item?.image} alt=""
                            />
                            <div className="overlay-bx">
                              <div className="overlay-icon align-b text-white text-left">
                                <div className="text-white text-left port-box">
                                  <h5>{item?.heading}</h5>
                                  <p>{item?.description}</p>
                                  <button
                                    className="site-button outline radius-xl white d-flex justify-content-center mt-3"
                                    onClick={() => {
                                      handleViewDeatils(item);
                                    }}
                                  >
                                    View Details
                                  </button>
                                  <Iconimage />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </Masonry>
              </ul>
            </div>



            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              onChangePage={(i) => setCurrentPage(i)}
            />
          </SRLWrapper>
        </SimpleReactLightbox>
      </>
    );
  }
);
export { GalleryGrid };
export default PortfolioGrid4;
