import React from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import Sidebar from './../../Element/Sidebar';
import bnr1 from './../../../images/banner/bnr1.jpg';

function PrivacyPolicy() {
    return (
        <>
           

            <div className="page-content bg-white">
                {/* <!-- inner page banner --> */}
                <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                    <PageTitle motherMenu='PRIVACY POLICY' activeMenu='PRIVACY POLICY' />
                </div>
                {/* <!-- inner page banner END --> */}

<div className="content-area">
    <div className="container privacy-container">
        <h2 className="privacy-heading">Privacy Policy</h2>
        <div className="privacy-content">
            <div className="section">
                <h3>Information Collection and Use</h3>
                <div className="mt-2">Your privacy is important to us. It is TECEADS SOLUTIONS PRIVATE LIMITED's policy to respect your privacy regarding any information we may collect from you across our website, teceads.com, and other sites we own and operate.</div>
                <div className="mt-2">We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</div>
                <div className="mt-2">We collect different types of information for various purposes to provide and improve our service to you. This includes information such as your name, email address, phone number, and any other details you provide us directly or through interactions with our services.</div>
                <div className="mt-2">When you access our services, we may also automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse our site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to our site, and information about how you interact with our site.</div>
                <div className="mt-2">Information may also be collected through the use of third-party services such as Google Analytics that collect, monitor, and analyze this type of information in order to increase our service's functionality. These third-party service providers have their own privacy policies addressing how they use such information.</div>
            </div>
            <div className="mt-3">
                <h3>Data Protection</h3>
                <div className="mt-2">We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. These measures include encryption, firewalls, and secure server facilities.</div>
                <div className="mt-2">All of our employees are trained on our privacy and security policies. Access to your personal information by our employees is limited to those who need it to provide you with services or to do their jobs.</div>
                <div className="mt-2">We regularly review our compliance with our Privacy Policy and periodically review our internal security policies and guidelines to ensure we are keeping up with the latest security technologies and methods.</div>
                <div className="mt-2">In the event of a data breach, we will promptly notify you and the relevant authorities if we believe your data has been compromised, in accordance with applicable law.</div>
                <div className="mt-2">We only engage with third-party service providers who are committed to maintaining the security and integrity of personal data, and who are subject to strict data processing terms and conditions.</div>
            </div>
            <div className="mt-3">
                <h3>Information Sharing and Disclosure</h3>
                <div className="mt-2">We don’t share any personally identifying information publicly or with third-parties, except when required to by law. Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</div>
                <div className="mt-2">We may employ third-party companies and individuals to facilitate our service ("Service Providers"), to provide the service on our behalf, to perform service-related services, or to assist us in analyzing how our service is used. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</div>
                <div className="mt-2">In the event that we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your personal information may be among the assets transferred.</div>
                <div className="mt-2">We may disclose your personal information for any other purpose with your consent.</div>
                <div className="mt-2">Our service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</div>
           </div>
            <div className="mt-3">
                <h3>Your Rights</h3>
                <div className="mt-2">You have the right to request access to the personal information we hold about you, to ensure it is accurate and up to date.</div>
                <div className="mt-2">If you believe that any information we are holding on you is incorrect or incomplete, please contact us as soon as possible. We will promptly correct any information found to be incorrect.</div>
                <div className="mt-2">You have the right to request that we delete your personal data under certain conditions. When your data is deleted, we will use reasonable measures to ensure that your personal information is irretrievably deleted.</div>
                <div className="mt-2">You have the right to request that we transfer the data we have collected to another organization, or directly to you, under certain conditions.</div>
                <div className="mt-2">Where we rely on your consent to process your personal information, you have the right to withdraw that consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.</div>
            </div>
            <div className="mt-3">
                <h3>Children's Privacy</h3>
                <div className="mt-2">We do not knowingly collect personally identifiable information from children under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us.</div>
                <div className="mt-2">If we become aware that we have collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.</div>
                <div className="mt-2">Parents and guardians have the right to request access to, correct, or delete any personal information that has been collected from their children. We encourage parents to monitor their children's online activities and to participate in and monitor their online activity.</div>
                <div className="mt-2">Our services are not directed to individuals under the age of 18. We do not intentionally collect personal information from children under 18. If we learn that we have collected personal information of a child under 18, we will take steps to delete the information as soon as possible.</div>
            </div>
            <div className="mt-3">
                <h3>Changes to This Privacy Policy</h3>
                <div className="mt-2">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</div>
                <div className="mt-2">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</div>
                <div className="mt-2">If we make material changes to how we treat our users' personal information, we will notify you through a notice on the website's homepage and/or via email to the email address specified in your account.</div>
                <div className="mt-2">We will keep prior versions of this Privacy Policy in an archive for your review.</div>
            </div>
            <div className="mt-3">
                <h3>Contact Us</h3>
                <div className="mt-2">If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at info@teceads.com for operational use, support@teceads.com for support, and admin@teceads.com for administrative inquiries. Our registered address is Floor 3, Ram Kutira, Sastri Nagar, Berhampur, Odisha, Pin 760001, and our operating office address is DCB-907, DLF Cybercity, Patia, Bhubaneswar, Odisha, Pin - 751024. Contact us at +91 9704505596.</div>
            </div>
        </div>
    </div>
</div>
</div>

            
        </>
    );
}

export default PrivacyPolicy;
