import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header1";
import Footer from "../Layout/Footer1";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";
import PageTitle from "../Layout/PageTitle";
import Masonry from "react-masonry-component";
import axios from "axios";
import { apiurl } from "../../api handler/apiurl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Pagination from "../../utils/pagination";
import { Button } from "reactstrap";
import MetaProvider from "../Helmet";

const Iconimage = (props) => {
  const { openLightbox } = useLightbox();

  return (
    <Link
      to={"#"}
      onClick={() => openLightbox(props.imageToOpen)}
      className="mfp-link"
    >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  );
};

const TagLi = ({ name, handlesettag, tagActive }) => {
  return (
    <li
      className={`tag ${tagActive ? "btn active" : "btn"}`}
      onClick={() => handlesettag(name)}
    >
      <input type="radio" />
      <Link to={"#"} className="site-button-secondry">
        <span>
          {name} {""}
        </span>{" "}
      </Link>
    </li>
  );
};

const Gallery = React.memo(() => {
  const [gallerytop, setGallerytop] = useState("");

  const fetchGalleryTopData = async () => {
    const res = await axios.post(
      `${apiurl}/gallery/gallery/getAll/getAllGalleryTop`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    setGallerytop(res?.data?.data[0]);
  };

  const [isLoaded__, setIsloaded__] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded__) {
            fetchGalleryTopData();
            setIsloaded__(true);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded__]);

  return (
    <div ref={componentRef}>
      <div className="page-content bg-white">
        {gallerytop ? (
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: `url(${gallerytop?.topImg})` }}
          >
            <PageTitle motherMenu="Gallery" activeMenu="Gallery" />
          </div>
        ) : (
          <Skeleton height={300} baseColor="#556B2F" />
        )}

        <PortfolioItem gallerytop={gallerytop} />
      </div>
    </div>
  );
});

const PortfolioItem = React.memo(({ gallerytop }) => {
  const masonryOptions = {
    transitionDuration: 0,
  };
  const [tag, setTag] = useState("Show All");
  const [filteredImages, setFilteredImages] = useState([]);
  const [gallerybottom, setGallerybottom] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1)
  // const [selectedPageSize, setSelectedPageSize] = useState(5)
  // const [totalItemCount, setTotalItemCount] = useState(0);
  // const [totalPage, setTotalPage] = useState(1);

  const fetchGalleryBottomData = async () => {
    try {
      const res = await axios.post(
        `${apiurl}/gallery/gallery/getAll/getAllGalleryBottom`,
        // {
        //     pageSize: selectedPageSize,
        //     currentPage: currentPage,
        // },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (res?.data?.code === 200) {
        setGallerybottom(res?.data?.data);
        // setTotalItemCount(res?.data?.totalCount);
        // setTotalPage(Math.ceil(res?.data?.totalCount / selectedPageSize));
      }
    } catch (error) {
      // Handle error here
      console.error("Error fetching gallery bottom data:", error);
    }
  };

  useEffect(() => {
    // fetchGalleryBottomData(selectedPageSize, currentPage)
    fetchGalleryBottomData();
  }, []);

  const imagesLoadedOptions = { background: ".my-bg-image-el" };
  const [isLoaded__, setIsloaded__] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded__) {
            setIsloaded__(true);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded__]);

  useEffect(() => {
    if (tag === "Show All") {
      setFilteredImages(gallerybottom);
    } else {
      const filteredImages = gallerybottom.filter((image) =>
        image.tags.includes(tag)
      );
      setFilteredImages(filteredImages);
    }
  }, [tag, gallerybottom]);
  return (
    <div ref={componentRef} className="container-fluid">
    <MetaProvider title={gallerytop.heading} description={gallerytop?.description }/>

      <div className="section-head text-black text-center">
        <h4 className="text-gray-dark font-weight-300 m-b10">
          {gallerytop.heading || <Skeleton width={100} />}
        </h4>
        <h2 className="box-title m-tb0">
          {gallerytop?.subHeading || <Skeleton width={400} />}
          <span className="bg-primary"></span>
        </h2>
        <p>{gallerytop?.description || <Skeleton count={2} />}</p>
      </div>
      <div className="site-filters clearfix center m-b40">
        <ul className="filters" data-toggle="buttons">
          {gallerybottom.length === 0 ? (
            [...Array(5)].map((_, index) => (
              <Button className="mr-2">
                <Skeleton width={100} height={30} />
              </Button>
            ))
          ) : (
            <>
              <TagLi
                name="Show All"
                handlesettag={setTag}
                tagActive={tag === "Show All"}
              />
              {[...new Set(gallerybottom.map((item) => item.tags))].map(
                (tagName) => (
                  <TagLi
                    key={tagName}
                    name={tagName}
                    handlesettag={setTag}
                    tagActive={tag === tagName}
                  />
                )
              )}
            </>
          )}
        </ul>
      </div>
      <SimpleReactLightbox>
        <SRLWrapper>
          <div className="">
            <ul
              id="masonry"
              className="dlab-gallery-listing gallery-grid-4 mfp-gallery "
            >
              <Masonry
                className={"my-gallery-class"}
                options={masonryOptions}
                disableImagesLoaded={false}
                updateOnEachImageLoad={false}
                imagesLoadedOptions={imagesLoadedOptions}
              >
                {filteredImages.length === 0
                  ? [...Array(8)].map((_, index) => (
                      <li
                        className="card-container col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30 drupal php wordpress"
                        key={index}
                      >
                        <div className="dlab-box portfolio-box">
                          <div className="dlab-media dlab-img-effect dlab-img-overlay1">
                            <Skeleton height={450} />
                            <div className="overlay-bx">
                              <div className="overlay-icon text-white p-lr10">
                                <Iconimage />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                  : filteredImages.map((item, index) => (
                      <li
                        className="card-container col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30 drupal php wordpress"
                        key={index}
                      >
                        <div className="dlab-box portfolio-box">
                          <div className="dlab-media dlab-img-effect dlab-img-overlay1">
                            <img src={item?.galleryImg} alt="" />
                            <div className="overlay-bx">
                              <div className="overlay-icon text-white p-lr10">
                                <h5>{item?.galleryHeading}</h5>
                                <p className="m-b10">{item?.galleryDesc}</p>
                                <Iconimage />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                {/* <Pagination
                                    currentPage={currentPage}
                                    totalPage={totalPage}
                                    onChangePage={i => setCurrentPage(i)}
                                /> */}
              </Masonry>
            </ul>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  );
});

export default Gallery;
