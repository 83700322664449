import React, { Fragment, useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import axios from 'axios'
import { apiurl } from '../../api handler/apiurl';
const counterBlog = [
    { icon: <i className="ti-bag m-r10" />, num: '15', title: 'Years in Business' },
    { icon: <i className="ti-user m-r10" />, num: '700', title: 'Happy Clients' },
    { icon: <i className="flaticon-users m-r10" />, num: '50', title: 'Technical Experts' },
    { icon: <i className="ti-mobile m-r10" />, num: '200', title: 'Apps Delivered' },
];

const Counter = () => {

    const [whychooseusbuttom, setwhychooseusbuttom] = useState([])
    const whychooseusbottom = async () => {
        const res = await axios({
            method: "POST",
            url: `${apiurl}home/whyUsBottom/getAll/getAllWhyUsBottom`,
            headers: {
                'Accept': 'application/json',
                'content-Type': "application/json"
            }
        })
        setwhychooseusbuttom(res?.data?.data)

    }
    const fun = async () => {
        await whychooseusbottom()
    }


    const [isLoaded__, setIsloaded__] = useState(false)
    const componentRef = useRef()
    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                if (entry.isIntersecting && !isLoaded__) {
                    fun()
                    setIsloaded__(true)
                }

            })
        }, { threshold: 0.1 })
        if (componentRef.current) {
            observer.observe(componentRef.current)
        }

        return () => observer.disconnect();

    }, [isLoaded__])





    return (
        <div ref={componentRef}>
            <Fragment >
                <div className="container-fluid">
                    <div className="row choses-info-content">
                        {whychooseusbuttom?.map((data, index) => (
                            <div className="col-6 col-lg-3 col-md-3 col-sm-6 p-a30" key={index}>
                                <h2 className="m-t0 m-b10 font-weight-400 font-45"><i className={`${data?.icon} m-r10`}></i><span className="counter"><CountUp end={parseInt(data?.number)} duration={4} /></span>+</h2>
                                <h4 className="font-weight-300 m-t0">{data?.heading}</h4>
                            </div>
                        ))}
                    </div>
                </div>
            </Fragment>
        </div>
    );
};

export default Counter;
