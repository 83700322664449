import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import teceadsLogo from "../../images/teceadslogo.jpg"
import logo from './../../images/logo9.png';
import logo2 from './../../images/logo-black.png';
import HeaderMenu from './HeaderMenu';
import { apiurl } from '../../api handler/apiurl';
import axios from 'axios'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const Header1 = React.memo(() => {


    const [Navtop, setNavTopdata] = useState(null)
    const toggleFunc = () => {
        const sidebarmenu = document.querySelector('.myNavbar');
        sidebarmenu.classList.toggle('show');
    }
    useEffect(() => {

      
        const navIcon = document.querySelector('.navicon');
        navIcon?.addEventListener('click', toggleFunc);


        const checkLi = (current) => {
            current.parentElement.parentElement.querySelectorAll("li").forEach(el =>
                (current.parentElement !== el) ? el.classList.remove('open') : ''
            );

            setTimeout(() => {
                current.parentElement.classList.toggle('open');
            }, 100);
        }
        const navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li > a, .sub-menu > li > a'));
        navUl.forEach(item => {
            item?.addEventListener('click', function () { checkLi(this) });
        });


        return () => {
            navIcon.removeEventListener('click', toggleFunc);
            navUl.forEach(item => {
                item?.removeEventListener('click', function () { checkLi(this) });
            });
        }
    }, []);

    const fetchNavTop = async () => {
        const res = await axios({
            method: "POST",
            url: `${apiurl}/home/navTop/getAll/getAllNavTop`,
            headers: {
                'Accept': 'application/json',
                'content-Type': "application/json"
            }
        })

        setNavTopdata(res.data.data[0])

    }

    const [isLoaded__, setIsloaded__] = useState(false)
    const componentRef = useRef()
    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                if (entry.isIntersecting && !isLoaded__) {
                    fetchNavTop()
                    setIsloaded__(true)
                }

            })
        }, { threshold: 0.1 })
        if (componentRef.current) {
            observer.observe(componentRef.current)
        }

        return () => observer.disconnect();

    }, [isLoaded__])

    return (
        <header ref={componentRef} className="site-header header-transparent mo-left" id="fix-header">
            <div className="top-bar">
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <div className="dlab-topbar-left">
                            <ul>
                                <li><i className="flaticon-phone-call m-r5"></i><a href="tel:8763889700">( +91 ) {Navtop?.phone || <Skeleton height={10} width={100} />}</a></li>
                                <li><i className="ti-location-pin m-r5"></i> {Navtop?.address || <Skeleton height={10} width={350} />}</li>
                            </ul>
                        </div>
                        <div className="dlab-topbar-right">
                            <ul>
                                <li><i className="ti-linkedin m-r5" ></i>
                                <a href='https://www.linkedin.com/company/teceads-solutions/'  target='_blank' rel='noopener noreferrer'>{Navtop?.linkedin || <Skeleton height={10} width={100} />}
                                </a></li>
                                <li>  <i className="ti-email m-r5"></i> <a href={`mailto:${Navtop?.email}`}>
                                    {Navtop?.email || <Skeleton height={10} width={100} />}

                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sticky-header main-bar-wraper navbar-expand-lg">
                <div className="main-bar clearfix">
                    <div className="container clearfix">
                        <div className="logo-header mostion">
                            {Navtop?.logo ?
                                <Link to={'./'} className="dez-page">
                                    <img src={Navtop?.logo} alt="teceads logo" />
                                </Link>
                                :
                                <Skeleton height={57} width={100} />
                            }
                        </div>

                        <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>

                        {/* <div className="extra-nav">
                        <div className="extra-cell">
                            <Link to={'./contact'} className="dez-page site-button primary">Inquire Now </Link>
                        </div>
                    </div> */}

                        <div className="header-nav navbar-collapse collapse myNavbar justify-content-end" id="navbarNavDropdown">
                            <div className="logo-header mostion d-md-block d-lg-none">
                                <Link to={'/'} className="dez-page"><h4>Teceads Solutions</h4></Link>
                            </div>
                            {/*  Header Menu Contents  */}
                            <HeaderMenu  toggleFunc={toggleFunc}/>
                            {/*  Header Menu Contents End */}
                        </div>
                    </div>
                </div>
            </div>
        </header>

    );
})

export default Header1;
