import { Suspense, lazy, useRef } from "react";
import Footer from "./../../Layout/Footer1";
import React, { useEffect, useState } from "react";

import bg2 from "./../../../images/background/bg-map.jpg";

import axios from "axios";
import { apiurl } from "../../../api handler/apiurl";
import "../../../css/Loader.css";
import MetaProvider from "../../Helmet";
const Header = lazy(() => import("./../../Layout/Header1"));
const AboutAboveBelt = lazy(() => import("./../../Element/AboutAboveBelt"));
const Aboutbelt = lazy(() => import("./../../Element/Aboutusbelt"));
const TestimonialCarousel = lazy(() =>
  import("./../../Element/TestimonialCarousel")
);
const FormStyle = lazy(() => import("./../../Element/FormStyle"));

export default function Aboutus1() {
  return (
    <>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        }
      >
        <div className="page-content bg-white">
          <AboutAboveBelt />
          <Aboutbelt />
          <VisionBlog />
          <div
            className="section-full content-inner"
            style={{
              backgroundImage: `url(${bg2})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="container">
              <div className="section-content m-b30 ">
                <TestimonialCarousel />
              </div>
            </div>
          </div>

          <FormStyle />
        </div>
      </Suspense>
    </>
  );
}

const VisionBlog = React.memo(() => {
  const [BelowBelt, setAboutUsBelowBelt] = useState(null);
  const fetchDataAboutBelowBelt = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${apiurl}aboutUs/belowBelt/getAll/getAllBelowBelt`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setAboutUsBelowBelt(res?.data?.data[0]);
    } catch (error) {
      console.error("Error fetching data for below belt:", error);
    }
  };

  const fun = async () => {
    await fetchDataAboutBelowBelt();
  };
  const [isLoaded__, setIsloaded__] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded__) {
            fun();
            setIsloaded__(true);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded__]);

  return (
    <div
      ref={componentRef}
      className="section-ful our-about-info content-inner-1 "
    >
      <div className="container">
        <div className="section-head text-center">
          <h2 className="box-title m-tb0">
            {BelowBelt?.p2Heading1}
            <span className="bg-primary"></span>
          </h2>
          <p>{BelowBelt?.p2Desc1}</p>
        </div>
        <div className="row dzseth  m-b30">
          <div className="col-lg-6 col-md-12 m-b30 about-img ">
            <img src={BelowBelt?.p2Image1} data-tilt alt="" />
          </div>
          <div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
            <div className="dis-tbl-cell">
              <h3 className="box-title">
                {BelowBelt?.p2Heading2}
                <span className="bg-primary"></span>
              </h3>
              <p className="font-16">{BelowBelt?.p2Desc2}</p>
            </div>
          </div>
        </div>
        <div className="row dzseth column-reverse">
          <div className="col-lg-6 col-md-12 dis-tbl text-justify">
            <div className="dis-tbl-cell">
              <h3 className="box-title">
                {BelowBelt?.p2Heading3}
                <span className="bg-primary"></span>
              </h3>
              <p className="font-16">{BelowBelt?.p2Desc3}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 about-img ">
            <img src={BelowBelt?.p2Image2} data-tilt alt="" />
          </div>
        </div>
      </div>
    </div>
  );
});
