import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiurl } from '../../api handler/apiurl';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import ContactusModal from './contactusModal';
const Footer1 = React.memo(() => {
    const [footerconatactus, setfootercontactus] = useState([])
    const [footer, setfooter] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchcontactusfooter = async () => {
        try {

            const res = await axios({
                method: "POST",
                url: `${apiurl}/contactUs/contactUs/getAll/getAllContactUs`,
                headers: {
                    'Accept': 'application/json',
                    'content-Type': "application/json"
                }
            })

            setfootercontactus(res?.data?.data)
        } catch (error) {
            console.log(error)
        }


    }

    const fetchfooter = async () => {
        try {
            const res = await axios({
                method: "POST",
                url: `${apiurl}/contactUs/footer/getAll/getAllFooter`,
                headers: {
                    'Accept': 'application/json',
                    'content-Type': "application/json"
                }
            })

            setfooter(res?.data?.data[0])

        } catch (error) {
            console.log(error)
        }


    }
    const fun = async () => {

        await fetchcontactusfooter()
        await fetchfooter()

    }

    const [isLoaded__, setIsloaded__] = useState(false)
    const componentRef = useRef()
    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                if (entry.isIntersecting && !isLoaded__) {
                    fun()
                    setIsloaded__(true)
                }

            })
        }, { threshold: 0.1 })
        if (componentRef.current) {
            observer.observe(componentRef.current)
        }

        return () => observer.disconnect();

    }, [isLoaded__])


    return (
        <div ref={componentRef} >
            <ContactusModal
                show={show}
                handleClose={handleClose}
            />
            <footer className="site-footer text-uppercase">
                <div className="footer-top bg-primary">
                    <div className="container" >
                        <div className="row">
                            <div className="col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                <div className="widget widget_services border-0">
                                    <h5 className="m-b30 text-white">Company</h5>
                                    <ul>
                                        <li className="active"><Link to={'/'}><span className="ti-home"></span></Link></li>
                                        <li><Link to={'/about-us'}>About Us </Link></li>
                                        <li><Link to={'/our-services'}>Services</Link></li>
                                        <li><Link to={'/our-products'}>Products</Link></li>
                                        <li><Link to={'/blogs'}>Blogs</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4">
                                <div className="widget widget_services border-0">
                                    <h5 className="m-b30 text-white">Useful Link</h5>
                                    <ul>
                                        <li><Link to={"/expertise"}>Expertise</Link></li>
                                        <li><Link to={"/project-management"}>Project Management</Link></li>
                                        <li><Link to={"/faqs"}>Faq's</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4">
                                <div className="widget widget_getintuch">
                                    <h5 className="m-b30 text-white ">Contact us</h5>
                                    {
                                        footerconatactus.length === 0 ? (
                                            <ul>
                                                <li><i className="ti-location-pin"></i><strong>Address</strong><Skeleton width={250} baseColor='#2F4F4F' count={2} /></li>
                                                <li><i className="ti-mobile"></i><strong>phone</strong><a href="tel:8763889700">( +91 ) <Skeleton width={100} baseColor='#2F4F4F' /></a></li>
                                                <li><i className="ti-email"></i><strong>email</strong><a href="mailto:teceads@gmail.com"> <Skeleton width={200} baseColor='#2F4F4F' /></a></li>
                                            </ul>
                                        ) : (
                                            footerconatactus?.map((item) => {
                                                return (
                                                    <ul key={item?._id}>
                                                        <li><i className="ti-location-pin"></i><strong>Address</strong>{item?.address}</li>
                                                        <li><i className="ti-mobile"></i><strong>phone</strong><a href="tel:8763889700">( +91 ) {item?.phone[0]}</a></li>
                                                        <li><i className="ti-email"></i><strong>email</strong><a href="mailto:teceads@gmail.com"> {item?.email[0]}</a></li>
                                                    </ul>
                                                )
                                            })
                                        )
                                    }



                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 ">
                                <div className="widget">
                                    {/* <h5 className="m-b30 text-white">{footer?.newsLetterHeading ||<Skeleton  baseColor='#2F4F4F'/>}</h5> */}
                                    <p className="text-capitalize m-b20">{footer?.newsLetterDesc || <Skeleton baseColor='#2F4F4F' count={4} />}</p>
                                    <div className="subscribe-form m-b20">

                                        {/* <div className="dzSubscribeMsg"></div> */}
                                        <div className="input-group">

                                            <button className="site-button" onClick={handleShow}>Get Started</button>

                                        </div>

                                    </div>
                                    <ul className="list-inline m-a0">
                                        <li><a href="https://www.facebook.com/teceads/" target="_blank" className="site-button facebook circle mr-1"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#" target="_blank" className="site-button google-plus circle mr-1"><i className="fa fa-google-plus"></i></a></li>
                                        <li><a href="https://www.linkedin.com/company/teceads-solutions/?originalSubdomain=in" target="_blank" className="site-button linkedin circle mr-1"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="https://www.instagram.com/teceads/?hl=en" target="_blank" className="site-button instagram circle mr-1"><i className="fa fa-instagram"></i></a></li>
                                        <li><Link to={"#"} className="site-button twitter circle mr-1"><i className="fa fa-twitter"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 text-left "> <span>{footer?.footerLeft || <Skeleton baseColor='#2F4F4F' width={230} />}</span> </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 text-right ">
                                <div className="widget-link ">
                                    <ul>
                                        <li><a href={'http://localhost:3000/sitemap.xml'} target='blank_'> {footer?.footerRight || <Skeleton baseColor='#2F4F4F' width={100} />}</a></li>
                                        <li><Link to={'/privacy-policy'}>Privacy-Policy</Link></li>
                                        <li><Link to={'/refund-policy'}>Refund-Policy</Link></li>
                                        <li><Link to={'/terms-condition'}>Terms & Conditions</Link></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
})

export default Footer1;
