import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import pic1 from './../../images/our-services/pic1.jpg';
import pic2 from './../../images/our-services/pic2.jpg';
import pic3 from './../../images/our-services/pic3.jpg';
import axios from 'axios';
import { apiurl } from '../../api handler/apiurl';
const SidebarCarousel = () => {
	const [image, setImage] = useState([])
	const [settings, setSettings] = useState({
		arrows: false,
		slidesToShow: 1,
		infinite: true,
		dots: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	});
	const fetchOurProjectDetails = async () => {
		try {
			const res = await axios({
				method: "POST",
				url: `${apiurl}/Portfolio/Portfoiloimage/getAll/getAllportfolioimage`,
				headers: {
					'Accept': 'application/json',
					'content-Type': "application/json"
				},


			})

			setImage(res?.data?.data)

		} catch (error) {
			console.log(error)
		}

	}
	useEffect(() => {
		fetchOurProjectDetails()
	}, [])



	return (
		<>
			<Slider className="testimonial-box center-active-owl item-center owl-loaded owl-carousel owl-none  owl-dots-black-full " {...settings} >

				<div className="item"><img src={image?.[image.length - 1]?.image} /></div>
				<div className="item"><img src={image?.[image.length - 2]?.image} alt="" /></div>
				<div className="item"><img src={image?.[image.length - 3]?.image} alt="" /></div>
			</Slider>
		</>
	);
}

export default SidebarCarousel;
