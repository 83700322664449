import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import axios from 'axios';
import { apiurl } from '../../api handler/apiurl';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ImgCarouselContent = React.memo(() => {
  const [HomeHeadslider, setHomeHeadSlider] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const componentRef = useRef();

  var settings = {
    arrows: false,
    slidesToShow: 4,
    speed: 3000,
    navSpeed: 3000,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const fetchHomeHeadslider = async () => {
    try {
      const res = await axios.post(`${apiurl}/home/homeSlider/getAll/getAllHomeSlider`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      setHomeHeadSlider(res?.data?.data);
    } catch (error) {
      console.error('Error fetching slider data:', error);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !isLoaded) {
          fetchHomeHeadslider();
          setIsLoaded(true);
        }
      });
    }, { threshold: 0.1 });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded]);

  return (
    <div style={{
      overflowX: 'auto',      // Enable horizontal scrolling
      overflowY: 'hidden',    // Hide vertical scrollbar initially
      whiteSpace: 'nowrap',   // Prevent wrapping
      WebkitOverflowScrolling: 'touch'  // Enable smooth scrolling on iOS
    }} ref={componentRef}>
      <Slider className="img-carousel-content owl-carousel text-center text-white owl-none " {...settings}>
        {HomeHeadslider.length === 0 ? (
          Array.from({ length: 4 }).map((_, index) => (
            <div className="item p-3" key={index}>
              <Skeleton height={100} />
            </div>
          ))
        ) : (
          HomeHeadslider.map((item, index) => (
            <div className="item p-3" key={index}>
              <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                <div className="icon-content">
                  <h5 className="dlab-tilte">
                    <span className="icon-sm"><i className={item?.logo}></i></span>
                    {item?.heading}
                  </h5>
                  <p style={{
                    whiteSpace: window.innerWidth <= 768 ? 'nowrap' : 'normal', 
                    overflow: 'hidden',      
                    textOverflow: 'ellipsis', 
                    margin: 0,              
                    padding: 0              
                  }}>{item?.subHeading}</p>
                </div>
              </div>
            </div>
          ))
        )}
      </Slider>
    </div>
  );
});

export default ImgCarouselContent;
