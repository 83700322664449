import React, { useState, useEffect, useRef } from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';
import SectionCounter from './../Element/SectionCounter';
import TestimonialCarousel from './../Element/TestimonialCarousel';
import FormStyle from './../Element/FormStyle';
import { apiurl } from '../../api handler/apiurl';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import rocket from './../../images/rocket.png';
import bnr from './../../images/banner/bnr7.jpg';
import tamplate from './../../images/tamplate.png';
import bg1 from './../../images/background/bg1.jpg';
import bgmap from './../../images/background/bg-map.jpg';
import ProjectMangementBelt from './ProjectMangementbelt';
import MetaProvider from '../Helmet';

const BlogLeft = ({ title, number, desc }) => {
    return (
        <>
            <div className="line-left clearfix">
                <div className="line-left-box">
                    <div className="line-content-box ">
                        <h4 className="min-title text-uppercase text-primary m-t0 m-b15">{title}</h4>
                        <p className="font-16">
                            {desc}
                        </p>
                    </div>
                    <div className="line-num bg-primary-dark">{number}</div>
                </div>
            </div>
        </>
    );
};
const BlogRight = ({ title, number, desc }) => {
    return (
        <>
            <div className="line-right clearfix">
                <div className="line-right-box">
                    <div className="line-content-box ">
                        <h4 className="min-title text-uppercase text-primary m-t0 m-b15">{title}</h4>
                        <p className="font-16">
                            {desc}
                        </p>
                    </div>
                    <div className="line-num bg-primary-dark">{number}</div>
                </div>
            </div>
        </>
    );
};

const ProjectManagement = () => {
    const [projectMangementData, setProjectMangementData] = useState()

    const projectManagementTop = async () => {
        const res = await axios({
            method: "POST",
            url: `${apiurl}/more/projectManagement/getAll/getAllProjectManagement`,
            headers: {
                'Accept': 'application/json',
                'content-Type': "application/json"
            },


        })
        setProjectMangementData(res?.data?.data[0])
      
    }
    const [isLoaded__, setIsloaded__] = useState(false)
    const componentRef = useRef()
    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                if (entry.isIntersecting && !isLoaded__) {
                    projectManagementTop();
                    setIsloaded__(true)
                }

            })
        }, { threshold: 0.1 })
        if (componentRef.current) {
            observer.observe(componentRef.current)
        }

        return () => observer.disconnect();

    }, [isLoaded__])
    return (
        <div ref={componentRef}>
        <MetaProvider title={projectMangementData?.heading } description={projectMangementData?.description}/>

            <div className="page-content bg-white">
                {/* <!-- inner page banner --> */}
                {projectMangementData ?
                    (<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: `url(${projectMangementData?.image})` }}>
                        <PageTitle motherMenu="ProjectMangement" activeMenu="ProjectMangement" />
                    </div>) :
                    (
                        <Skeleton height={300} baseColor='#556B2F' />
                    )}
                {/* <!-- inner page banner END --> */}
                <div className="content-block">
                    {/* <!-- About Us --> */}
                    <div className="section-full content-inner-1">
                        <div className="container">
                            <div className="section-head text-black text-center">
                                <h4 className="text-gray-dark m-b10">{projectMangementData?.heading || <Skeleton width={150} />}</h4>
                                <h2 className="box-title m-tb0">
                                    {projectMangementData?.subHeading || <Skeleton />}
                                    <span className="bg-primary"></span>
                                </h2>
                                <p>
                                    {projectMangementData?.description || <Skeleton count={3} />}
                                </p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <img src={rocket} alt="" className="fa faa-horizontal animated" />
                                    <h5 className="text-primary">Start Project</h5>
                                </div>
                                <div className="col-lg-12">
                                    <div className="time-line clearfix">

                                        {projectMangementData?.card?.map((item, index) => {
                                            let counter = index + 1;
                                            return (
                                                <div key={index}>
                                                    {index % 2 === 0 ? (
                                                        <BlogLeft title={item?.heading} desc={item?.description} number={counter} />
                                                    ) : (
                                                        <BlogRight title={item?.heading} desc={item?.description} number={counter} />
                                                    )}
                                                </div>
                                            );
                                        })}



                                    </div>
                                </div>
                                <div className="col-lg-12 text-center">
                                    <h5 className="text-primary">END</h5>
                                    <div className="arrow-bx fa faa-horizontal animated"></div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <img src={tamplate} alt="" />
                        </div>
                    </div>
                    {/* <!-- Our Services --> */}
                    {/* <!-- Why Chose Us --> */}
                    <ProjectMangementBelt />
                    {/* <!-- Why Chose Us End --> */}
                    {/* <!-- Testimonials --> */}
                    <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgmap + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                        <div className="container">

                            <div className="section-content m-b30  ">
                                <TestimonialCarousel />
                            </div>
                        </div>
                    </div>
                    {/* <!-- Testimonials END --> */}
                    {/* <!-- Get in touch --> */}

                    <FormStyle />

                    {/* <!-- Get in touch --> */}
                </div>
                {/* <!-- contact area END --> */}
            </div>
        </div>
    )
}

export default ProjectManagement;
