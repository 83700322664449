import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import axios from 'axios';
import { apiurl } from '../../api handler/apiurl';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
const EventSlider = () => {
	const [fetchourService, setfetchOurservice] = useState("")
	const [ourserviceslider, setserviceslider] = useState([])

	const history = useHistory();

	const settings = {
		arrows: false,
		slidesToShow: 4,
		speed: 1500,
		navSpeed: 1500,
		infinite: true,
		autoplay: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				}
			}
		]



	};
	const fetchOurService = async () => {
		try {
			const res = await axios({
				method: "POST",
				url: `${apiurl}/services/servicetop/getAll/getAllServiceTop`,
				headers: {
					'Accept': 'application/json',
					'content-Type': "application/json"
				}
			})


			setfetchOurservice(res?.data?.data[0])
		}
		catch (error) {
			console.log(error)
		}

	}
	const fetchOurserviceslider = async () => {
		try {

			const res = await axios({
				method: "POST",
				url: `${apiurl}/services/serviCard/getAll/getAllServiceCard`,
				headers: {
					'Accept': 'application/json',
					'content-Type': "application/json"
				}
			})


			setserviceslider(res?.data?.data)
		} catch (error) {
			console.log(error)
		}

	}
	const fun = async () => {
		await fetchOurserviceslider()
		await fetchOurService()

	}


	const [isLoaded__, setIsloaded__] = useState(false)
	const componentRef = useRef()
	useEffect(() => {

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {

				if (entry.isIntersecting && !isLoaded__) {
					fun()
					setIsloaded__(true)
				}

			})
		}, { threshold: 0.1 })
		if (componentRef.current) {
			observer.observe(componentRef.current)
		}

		return () => observer.disconnect();

	}, [isLoaded__])

	const handelreadmore = (data) => {
		const url = `/service-details/${data.url.replace("/", "-")}`;
		history.push(url);


	}
	return (
		<div ref={componentRef} className="section-full content-inner-2">
			<div className="container">
				<div className="section-head text-black text-center">
					<h4 className="text-gray-dark m-b10">{fetchourService?.heading || <Skeleton height={30} width={150} />} </h4>
					<h2 className="box-title m-tb0">{fetchourService?.subHeading || <Skeleton width={550} />}<span className="bg-primary"></span></h2>
					<p>{fetchourService?.description || <Skeleton count={3} />}</p>
				</div>
			</div>
			<div className="development-box">
				<div className="container">
					<Slider className="img-carousel-content owl-carousel owl-none" {...settings}>
						{ourserviceslider.length === 0 ? (

							[...Array(4)].map((_, index) => (
								<div className="item p-1" key={index}>
									<div className="box-item-service text-center">
										{index % 2 === 0 ? (
											<>
												<div className="item-service-content m-b40">
													<h5><Skeleton /></h5>
													<p className="m-b0"><Skeleton count={2} /></p>
												</div>
												<div className="clearfix">
													<span className="text-primary round-center"></span>
												</div>
												<div className="icon-bx-md radius border-1 m-t40 m-b20">
													<span className="icon-cell"><i className={<Skeleton />}></i></span>
												</div>
												<button className="site-button outline outline-2"><span className="font-weight-500"><Skeleton width={60} /></span></button>


											</>
										) : (
											<>
												<div className="icon-bx-md radius border-1 m-b20 m-t0">
													<span className="icon-cell"><i className={<Skeleton />}></i></span>
												</div>
												<button className="site-button outline outline-2 m-b40" ><span className="font-weight-500"><Skeleton width={60} /></span></button>
												<div className="clearfix">
													<span className="text-primary round-center"></span>
												</div>
												<div className="item-service-content m-t40">
													<h5><Skeleton /></h5>
													<p className="m-b0"><Skeleton count={2} /></p>
												</div>
											</>
										)}
									</div>
								</div>
							))

						) : (

							ourserviceslider.slice(0, Math.floor(ourserviceslider.length / 2) * 2).map((item, index) => (
								<div className="item p-1" key={index}>
									<div className="box-item-service text-center">
										{index % 2 === 0 ? (
											<>
												<div className="item-service-content m-b40">
													<h5 style={{
														display: '-webkit-box',
														WebkitLineClamp: 2,
														WebkitBoxOrient: 'vertical',
														overflow: 'hidden',
														height: '2.8em',
														lineHeight: '1.4em'
													}}>{item?.heading}</h5>
													<p className="m-b0">{item.description.length > 100 ? item.description.slice(0, 100) + '...' : item.description}</p>
												</div>
												<div className="clearfix">
													<span className="text-primary round-center"></span>
												</div>
												<div className="icon-bx-md radius border-1 m-t40 m-b20">
													<span className="icon-cell"><i className={item?.icon}></i></span>
												</div>
												<button className="site-button outline outline-2" onClick={() => { handelreadmore(item) }}><span className="font-weight-500">Read More</span></button>
											</>
										) : (
											<>
												<div className="icon-bx-md radius border-1 m-b20 m-t0">
													<span className="icon-cell"><i className={item?.icon}></i></span>
												</div>
												<button className="site-button outline outline-2 m-b40" onClick={() => { handelreadmore(item) }}><span className="font-weight-500">Read More</span></button>
												<div className="clearfix">
													<span className="text-primary round-center"></span>
												</div>
												<div className="item-service-content m-t40">
													<h5 style={{
														display: '-webkit-box',
														WebkitLineClamp: 2,
														WebkitBoxOrient: 'vertical',
														overflow: 'hidden',
														height: '2.8em',
														lineHeight: '1.4em'
													}}>{item?.heading}</h5>
													<p className="m-b0">{item.description.length > 100 ? item.description.slice(0, 80) + '...' : item.description}</p>
												</div>
											</>
										)}
									</div>
								</div>
							))

						)}
					</Slider>

				</div>
			</div>
		</div>



	)

}



export default EventSlider;