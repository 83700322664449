import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "./../Layout/PageTitle";
import AccordionBlog from "./../Element/AccordionBlog";
import VideoPopup from "./../Element/VideoPopup";
import { apiurl } from "../../api handler/apiurl";
import axios from "axios";
import bnr1 from "./../../images/banner/bnr4.jpg";
import pattern from "./../../images/pattern/pic1.jpg";
import about from "./../../images/about/pic10.jpg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AccordionFaq from "../Element/AccordionFaq";
import MetaProvider from "../Helmet";

const Faqs = React.memo(() => {
  const [faq, setFaqdata] = useState(null);
  const [faqCard, setFaqCard] = useState([]);
  const fetchFaqTopImage = async () => {
    try {
      const res = await axios.post(
        `${apiurl}/more/faqsTop/getAll/getAllFaqsTop`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      setFaqdata(res?.data?.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchFaqCardData = async () => {
    try {
      const res = await axios.post(
        `${apiurl}/more/faqsCard/getAll/getAllFaqsCard`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      setFaqCard(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchData = async () => {
    await fetchFaqTopImage();
    await fetchFaqCardData();
  };

  const [isLoaded__, setIsloaded__] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded__) {
            fetchData();
            setIsloaded__(true);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded__]);

  return (
    <div ref={componentRef}>
              <MetaProvider title={faq?.heading } description={faq?.description}/>

      <div className="page-content bg-white">
        {faq ? (
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: `url(${faq?.image})` }}
          >
            <PageTitle motherMenu="Faq" activeMenu="Faq" />
          </div>
        ) : (
          <Skeleton height={300} baseColor="#556B2F" />
        )}

        <div className="content-block">
          <div className="container mt-3">
            <div className="section-head text-black text-center">
              <h3>{faq?.heading || <Skeleton width={400} />}</h3>
              <div className="dlab-separator bg-primary"></div>
              <p>{faq?.description || <Skeleton count={2} />}</p>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="faq-video">
                  <img
                    src={faq?.imageUrl}
                    alt=""
                    className="img-cover radius-sm"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <AccordionFaq faq={faq} />
              </div>
            </div>
            {/* Faq Info */}
            <div className="row">
              {faqCard.length === 0
                ? [...Array(3)].map((_, index) => (
                    <div
                      className="col-lg-4 col-md-4 col-sm-6 m-b30"
                      key={index}
                    >
                      <div className="icon-bx-wraper bx-style-1 bg-white p-a30 left">
                        <Skeleton height={150} />
                      </div>
                    </div>
                  ))
                : faqCard.map((item, index) => (
                    <div
                      className="col-lg-4 col-md-4 col-sm-6 m-b30"
                      key={index}
                    >
                      <div className="icon-bx-wraper bx-style-1 bg-white p-a30 left">
                        <div className="icon-md text-primary m-b20">
                          <Link to={"#"} className="icon-cell">
                            <i className={`${item.icon}`}></i>
                          </Link>
                        </div>
                        <div className="icon-content">
                          <h5 className="dlab-tilte text-uppercase">
                            {item.heading}
                          </h5>
                          <p>{item?.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>

            {/* Faq Info END */}
          </div>

          {/* Your Faq End */}
        </div>
        {/* contact area END */}
      </div>
    </div>
  );
});

export default Faqs;
