import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios'
import { apiurl } from '../../api handler/apiurl';
import Counter from './../Element/Counter';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const HomeTab = () => {
	const [whychooseusmain, setwhychooseusmain] = useState([])

	const [whychooseTop, setwhychooseustop] = useState("")
	const [activeTab, setActiveTab] = useState();


	const ftechWhychooseustop = async () => {

		const res = await axios({
			method: "POST",
			url: `${apiurl}home/whyUsTop/getAll/getAllWhyUsTop`,
			headers: {
				'Accept': 'application/json',
				'content-Type': "application/json"
			}

		})
		setwhychooseustop(res?.data?.data[0])

	}


	const Fetchwhychooseusmain = async () => {
		const res = await axios({
			method: "POST",
			url: `${apiurl}/home/whychooseusmain/getAll/getAllwhychoosemain`,
			headers: {
				'Accept': 'application/json',
				'content-Type': "application/json"
			}

		})

		setwhychooseusmain(res?.data?.data)
		setActiveTab(res?.data?.data[0]?._id)
	}
	const fun = async () => {
		await ftechWhychooseustop()
		await Fetchwhychooseusmain()
	}


	const [isLoaded__, setIsloaded__] = useState(false)
	const componentRef = useRef()
	useEffect(() => {

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {

				if (entry.isIntersecting && !isLoaded__) {
					fun()
					setIsloaded__(true)
				}

			})
		}, { threshold: 0.1 })
		if (componentRef.current) {
			observer.observe(componentRef.current)
		}

		return () => observer.disconnect();

	}, [isLoaded__])


	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}


	return (
		// <img src={item.image} className="w-100" alt="" />    `url(${whychooseTop.image})` 
		<div ref={componentRef} className="section-full content-inner-1 overlay-primary choseus-tabs bg-img-fix" style={{
			backgroundImage: whychooseTop?.image ? `url(${whychooseTop?.image})` : 'none',
			maxWidth: "100%",
			height: "auto",
			backgroundSize: "cover", // Ensures the image covers the entire container
		}}>

			<div className="container">
				<div className="section-head text-white text-center">
					<h2 className="box-title m-tb0">{whychooseTop?.heading || <Skeleton width={100} />}<span className="bg-primary"></span></h2>
					<p>{whychooseTop?.description || <Skeleton count={2} height={20} />}</p>
				</div>
			</div>
			<Fragment>
				<div className="container-fluid">
					<div className="dlab-tabs choseus-tabs">
						{whychooseusmain.length === 0 ? (
							<ul className="nav row justify-content-center" id="myTab" role="tablist">
								{[...Array(5)].map((_, index) => (
									<li key={index}>
										<Link to="#">
											<Skeleton height={20} width={100} />
										</Link>
									</li>
								))}
							</ul>
						) : (
							<ul className="nav row justify-content-center" id="myTab" role="tablist">
								{whychooseusmain?.map((item) => (
									<li key={item._id}>
										<Link
											to="#"
											className={classnames({ active: activeTab === item?._id })}
											onClick={() => toggle(item?._id)}
										>
											<span className="title-head">{item?.Type}</span>
										</Link>
									</li>
								))}
							</ul>
						)}

					</div>
				</div>
				<div className="container">
					<div className="tab-content chosesus-content">
						{whychooseusmain?.length === 0 ? (

							<div>
								<TabContent activeTab={activeTab}>
									<TabPane >
										<div className="row p-tb50">
											<div className="col-lg-6 m-b10 col-md-4 about-img">
												<Skeleton height={515} width={500} />
											</div>
											<div className="col-lg-6 col-md-8 text-white">
												<div className="p-l30 ">
													<h4 className="font-weight-300"><Skeleton /></h4>
													<ul className="list-checked primary">
														<li><span><Skeleton /></span></li>
														<li><span><Skeleton /></span></li>
														<li><span><Skeleton /></span></li>
													</ul>
												</div>
												<div className="abuot-box p-lr30" >
													<p><Skeleton count={5} /></p>
												</div>
											</div>
										</div>
									</TabPane>
								</TabContent>
							</div>
						) : (whychooseusmain?.map((item) => {

							return (
								<div key={item._id} id={item._id} className={`tab-pane ${activeTab === item._id ? 'active' : ''}`}>
									<TabContent activeTab={activeTab}>
										<TabPane tabId={item._id}>
											<div className="row p-tb50">
												<div className="col-lg-6 m-b10 col-md-4 about-img">
													<img src={item.image} alt=""
														className="w-100 " style={{ height: '515px' }}
													/>
												</div>
												<div className="col-lg-6 col-md-8 text-white">
													<div className="p-l30 ">
														<h4 className="font-weight-300">{item?.heading}</h4>
														<ul className="list-checked primary">
															<li><span>{item?.Point1}</span></li>
															<li><span>{item?.Point2}</span></li>
															<li><span>{item?.Point3}</span></li>
														</ul>
													</div>
													<div className="abuot-box p-lr30">
														<p>{item?.description}</p>
													</div>
												</div>
											</div>
										</TabPane>
									</TabContent>
								</div>
							)
						}))}


					</div>
				</div>
			</Fragment>
			<div className="choses-info text-white">
				<Counter />
			</div>
		</div>


	)

}
export default HomeTab;