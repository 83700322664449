import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/Header1";
import Footer from "./../Layout/Footer1";
import PageTitle from "./../Layout/PageTitle";
import ImgCarousel from "./../Element/ImgCarousel";
import ClientCarousel from "./../Element/ClientCarousel";
import bnr1 from "./../../images/banner/bnr2.jpg";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { apiurl } from "../../api handler/apiurl";
import axios from "axios";
import "../../css/Loader.css";

import VideoPopup from "../Element/VideoPopup";
import AccordionBlog from "../Element/AccordionBlog";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MetaProvider from "../Helmet";
export default function ProjectDetails() {
  function formatToDisplayDate(dateString) {
    let date = new Date(dateString);
    let day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let year = date.getFullYear();
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    return `${day}/${month}/${year}`;
  }
  const history = useHistory();
  const { portfolioId } = useParams();
  const [allprojectdetail, setallprojectdetail] = useState({});

  const FetchAllprojectDetails = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${apiurl}/Portfolio/Portfoiloimage/get/getprojectdetailssByUrl/${portfolioId}`,
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
        },
      });
      setallprojectdetail(res?.data?.data);
    } catch (error) {
      console.log(error);
      history.push("/our-portfolio");
    }
  };

  const radius = 35;
  const circumference = 2 * Math.PI * radius;
  const calculateStrokeDashoffset = (percentage) => {
    const strokeLength = (percentage / 100) * circumference;
    return circumference - strokeLength;
  };

  useEffect(() => {
    const fun = async () => {
      await Promise.all([FetchAllprojectDetails()]);
    };
    fun();
  }, []);

  return (
    <div className="page-wraper">
                  <MetaProvider title={allprojectdetail?.heading } description={allprojectdetail?.description} imageUrl={allprojectdetail.image0}/>

      <div className="page-content bg-white">
        {/* <!-- inner page banner --> */}
        {allprojectdetail?.image0 ? (
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: `url(${allprojectdetail.image0})`}}
          >
            <PageTitle
              motherMenu={allprojectdetail?.heading}
              activeMenu={allprojectdetail?.description}
            />
          </div>
        ) : (
          <Skeleton baseColor="#556B2F" height={300} />
        )}

        {/* <!-- inner page banner END --> */}
        <div className="content-block">
          {/* <!-- Project Details --> */}
          <div className="section-full content-inner-2">
            <div className="container">
              <div className="row m-b40">
                <div className="col-lg-6 col-md-6 m-b20">
                  <h2 className="m-b10 m-t0">
                    {allprojectdetail?.heading1 || <Skeleton />}
                  </h2>
                  <h5 className="m-b20">
                    {allprojectdetail?.subheading || <Skeleton />}
                  </h5>
                  <p className="m-b10">
                    {allprojectdetail?.description1 || <Skeleton count={5} />}
                  </p>
                </div>
                <div className="col-lg-6 col-md-6">
                  <ImgCarousel allprojectdetail={allprojectdetail} />
                </div>
              </div>
              <div className="row m-b50">
                <div className="col-md-6 m-b20">
                  <div className="p-a30 bg-gray">
                    <h6>
                      Project Name:{" "}
                      <span className="m-l10 font-weight-300 text-gray-dark">
                        {" "}
                        {allprojectdetail?.ProjectName || (
                          <Skeleton width={200} />
                        )}
                      </span>
                    </h6>
                    <h6>
                      Client:{" "}
                      <span className="m-l10 font-weight-300 text-gray-dark">
                        {" "}
                        {allprojectdetail?.Client || <Skeleton width={200} />}
                      </span>
                    </h6>
                    <h6>
                      Project Commencement Date:{" "}
                      <span className="m-l10 font-weight-300 text-gray-dark">
                        {" "}
                        {allprojectdetail?.ProjectCommencementDate ? (
                          formatToDisplayDate(
                            allprojectdetail?.ProjectCommencementDate
                          )
                        ) : (
                          <Skeleton width={200} />
                        )}
                      </span>
                    </h6>
                    <h6>
                      Project Completion Date:{" "}
                      <span className="m-l10 font-weight-300 text-gray-dark">
                        {allprojectdetail?.ProjectCompletionDate ? (
                          formatToDisplayDate(
                            allprojectdetail?.ProjectCompletionDate
                          )
                        ) : (
                          <Skeleton width={200} />
                        )}
                      </span>
                    </h6>
                    <h6>
                      Project URL:{" "}
                      <span className="m-l10 font-weight-300 text-gray-dark">
                        {allprojectdetail?.Projecturl || (
                          <Skeleton width={200} />
                        )}
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="col-md-6 align-self-center">
                  <h5 className="text-black fw5 m-b10">
                    {allprojectdetail?.heading2 || <Skeleton count={3} />}
                  </h5>
                  <p className="m-b0">
                    {allprojectdetail?.description2 || <Skeleton count={4} />}
                  </p>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row m-b30">
                <div className="col-lg-4 col-md-12 align-self-center">
                  <h5>{allprojectdetail?.heading3 || <Skeleton />}</h5>
                  <h2 className="font-weight-700">
                    {allprojectdetail?.subheading1 || <Skeleton count={2} />}
                  </h2>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="row">
                    {!allprojectdetail
                      ? [...Array(3)].map((_, index) => (
                          <div
                            className="col-lg-4 col-md-4 col-sm-6"
                            key={index}
                          >
                            <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                              <Skeleton />
                            </div>
                          </div>
                        ))
                      : allprojectdetail?.Piechartdetails?.map(
                          (item, index) => {
                            const strokeDashoffset = calculateStrokeDashoffset(
                              item.PieChartPercentage
                            );
                            return (
                              <div
                                className="col-lg-4 col-md-4 col-sm-6"
                                key={index}
                              >
                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                  <svg
                                    className="radial-progress"
                                    data-percentage="90"
                                    viewBox="0 0 80 80"
                                  >
                                    <circle
                                      className="incomplete"
                                      cx="40"
                                      cy="40"
                                      r="35"
                                    ></circle>
                                    <circle
                                      className="complete"
                                      cx="40"
                                      cy="40"
                                      r="35"
                                      style={{
                                        strokeDashoffset: strokeDashoffset,
                                      }}
                                    ></circle>
                                    <text
                                      className="percentage"
                                      x="50%"
                                      y="57%"
                                      transform="matrix(0, 1, -1, 0, 80, 0)"
                                    >
                                      {item?.PieChartPercentage}%
                                    </text>
                                  </svg>
                                  <div className="icon-content">
                                    <h5 className="font-weight-500">
                                      {item?.PieChartdata}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                  </div>
                </div>
              </div>
            </div>
            <center>
              <div className="row  w-75">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="faq-video">
                    <img src={allprojectdetail?.AccImage} alt="" className="img-cover radius-sm" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                  <AccordionBlog allprojectdetail={allprojectdetail} />
                </div>
              </div>
            </center>

            <div className="container">
              <ClientCarousel allprojectdetail={allprojectdetail} />
            </div>
          </div>
        </div>
        {/* <!-- contact area END --> */}
      </div>
    </div>
  );
}
