import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Counter from '../Element/Counter';
import axios from 'axios';
import { apiurl } from '../../api handler/apiurl';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const  ProjectMangementBelt = () => {
    const [fetchProjectmnagementbeltdata, setProjectMangementbelt] = useState([])

    const fetchExpertiseBelt = async () => {
        const res = await axios({
            method: "POST",
            url: `${apiurl}/more/projectManageBelt/getAll/getAllProjectManageBelt`,
            headers: {
                'Accept': 'application/json',
                'content-Type': "application/json"
            },

        })
    
        setProjectMangementbelt(res?.data?.data[0])

    }
    const [isLoaded__, setIsloaded__] = useState(false)
    const componentRef = useRef()


    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                if (entry.isIntersecting && !isLoaded__) {
                    fetchExpertiseBelt()
                    setIsloaded__(true)
                }

            })
        }, { threshold: 0.1 })
        if (componentRef.current) {
            observer.observe(componentRef.current)
        }

        return () => observer.disconnect();

    }, [isLoaded__])

    return (

        <div ref={componentRef} className="section-full content-inner-1 overlay-primary about-service bg-img-fix" style={{ backgroundImage: `url(${fetchProjectmnagementbeltdata?.image})` }}>
            <div className="container">
                <div className="section-head text-white text-center">
                    <h2 className="box-title m-tb0 max-w800 m-auto">{fetchProjectmnagementbeltdata?.heading || <Skeleton />}<span className="bg-primary"></span></h2>
                    <p>{fetchProjectmnagementbeltdata?.subHeading || <Skeleton />}</p>
                </div>
            </div>
            {fetchProjectmnagementbeltdata ? (
            <div className="container">
                <div className="row text-white">
                    <div className="col-lg-4 col-md-4 col-sm-6 m-b30">
                    <div className="icon-bx-wraper bx-style-1 p-a30 center">
                        <div className="icon-lg text-white m-b20"> <Link to={"#"} className="icon-cell text-white"></Link><i className={fetchProjectmnagementbeltdata?.icon1}></i></div>
                        <div className="icon-content">
                            <h5 className="dlab-tilte text-uppercase">{fetchProjectmnagementbeltdata?.title1}</h5>
                            <p>{fetchProjectmnagementbeltdata?.desc1}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 m-b30">
                    <div className="icon-bx-wraper bx-style-1 p-a30 center">
                        <div className="icon-lg text-white m-b20"> <Link to={"#"} className="icon-cell text-white"></Link><i className={fetchProjectmnagementbeltdata?.icon2}></i></div>
                        <div className="icon-content">
                            <h5 className="dlab-tilte text-uppercase">{fetchProjectmnagementbeltdata?.title2}</h5>
                            <p>{fetchProjectmnagementbeltdata?.desc2}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 m-b30">
                    <div className="icon-bx-wraper bx-style-1 p-a30 center">
                        <div className="icon-lg text-white m-b20"> <Link to={"#"} className="icon-cell text-white"></Link><i className={fetchProjectmnagementbeltdata?.icon3}></i></div>
                        <div className="icon-content">
                            <h5 className="dlab-tilte text-uppercase">{fetchProjectmnagementbeltdata?.title3}</h5>
                            <p>{fetchProjectmnagementbeltdata?.desc3}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            ) : (
                <div className="container">
                    <div className="row text-white">
                        {[...Array(3)].map((_, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
                                <div className="icon-bx-wraper bx-style-1 p-a30 center">
                                    <div className="icon-lg text-white m-b20">
                                        <Skeleton height={50} />
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="dlab-tilte text-uppercase"><Skeleton /></h5>
                                        <p><Skeleton count={3} /></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
            }



            <div className="choses-info text-white">
                <Counter />
            </div>
        </div >

    );
};

export default ProjectMangementBelt;
