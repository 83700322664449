import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className="owl-next flaticon-right-arrow" onClick={onClick} />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className=" owl-prev flaticon-left-arrow" onClick={onClick} style={{ zIndex: 1 }} />
		</div>
	);
}

const ImgCarousel = React.memo(({ allprojectdetail }) => {

	const [imagedata, setimagedata] = useState(allprojectdetail?.image1)


	const settings = {
		arrows: true,
		slidesToShow: 1,
		speed: 1500,
		fade: true,
		navSpeed: 1500,
		infinite: true,
		autoplay: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};
	useEffect(() => {
		setimagedata(allprojectdetail?.image1)
	}, [allprojectdetail])

	return (
		<Slider className="owl-fade-one owl-loaded  owl-btn-center-lr owl-btn-1 black owl-drag" {...settings}>
			{!imagedata ? (
				<Skeleton height={400}/>
				
			): (imagedata?.map((item, index) => {
				return (
			<div className="item" key={index}>
				<img src={item?.imgurl} alt={item?._id} style={{objectFit:"fill"}}  />
			</div>
			);
			}))}



		</Slider>
	);
}
)
export default ImgCarousel;
