import React from 'react';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import bnr1 from './../../../images/banner/bnr1.jpg';

function RefundPolicy() {
    return (
        <>
           

            <div className="page-content bg-white">
                {/* <!-- inner page banner --> */}
                <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: `url(${bnr1})` }}>
                    <PageTitle motherMenu='REFUND POLICY' activeMenu='REFUND POLICY' />
                </div>
                {/* <!-- inner page banner END --> */}
                <div className="container refund-container">
                    <h2 className="refund-heading">Refund Policy</h2>
                    <div className="refund-content">
                        <p>Thank you for choosing TECEADS SOLUTIONS PRIVATE LIMITED for your IT consultancy needs. We want to ensure that you are completely satisfied with our services. However, if you are not satisfied with our services, we offer a refund under the following conditions:</p>
                        <h3>Refund Eligibility:</h3>
                        <ul>
                            <li>We will issue a refund if you are not satisfied with the quality of our services.</li>
                            <li>If our consultancy services fail to meet the requirements outlined in the agreement or contract.</li>
                            <li>If you cancel the project before any work has started, you are eligible for a full refund.</li>
                        </ul>
                        <h3>Refund Process:</h3>
                        <p>To request a refund, please contact our customer support team at +91 9704505596 within 15 days of the completion of the project. We may ask for additional information regarding the reason for the refund request.</p>
                        <p>Once your refund request is approved, we will process the refund within 15 days. Please note that it may take some time for the refunded amount to appear in your account, depending on your payment method and financial institution.</p>
                        <h3>Exceptions:</h3>
                        <p>Please note that certain circumstances may not be eligible for a refund:</p>
                        <ul>
                            <li>If you have already received the deliverables and approved them.</li>
                            <li>If the project was canceled due to reasons beyond our control, such as force majeure events.</li>
                            <li>If the refund request is made after 15 days of project completion.</li>
                        </ul>
                        <p>If you have any questions or concerns regarding our refund policy, please feel free to contact us.</p>
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default RefundPolicy;
