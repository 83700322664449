import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./../../Layout/Header1";
import Footer from "./../../Layout/Footer1";
import PageTitle from "./../../Layout/PageTitle";
import SectionCounter from "./../../Element/SectionCounter";
import TestimonialCarousel from "./../../Element/TestimonialCarousel";
import FormStyle from "./../../Element/FormStyle";
import axios from "axios";
import { apiurl } from "../../../api handler/apiurl";
import { useLocation } from "react-router-dom";
import bnr1 from "./../../../images/banner/bnr4.jpg";
import bgmap from "./../../../images/background/bg-map.jpg";
import bg1 from "./../../../images/background/bg1.jpg";
import "../../../css/Loader.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MetaProvider from "../../Helmet";

const ServicesDetails = React.memo(() => {
  const history = useHistory();
  const [servicedetailsdata, setservicedeatilsdata] = useState(null);
  const [expandedList, setExpandedList] = useState([]);

  const location = useLocation();

  const { serviceId } = useParams();

  const fetchSreviceDetailsurl = async (serviceId) => {
    try {
      const res = await axios({
        method: "POST",
        url: `${apiurl}/services/serviCard/get/getservicedeatilsbyurl/${serviceId}`,
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
        },
      });

      setservicedeatilsdata(res?.data?.data);

      setExpandedList(new Array(res?.data?.data?.CardData?.length).fill(false));
    } catch (error) {
      console.error("Error fetching service data:", error);
      history.push("/service");
    }
  };

  const fetchData = async () => {
    serviceId && (await fetchSreviceDetailsurl(serviceId));
  };

  const [isLoaded__, setIsloaded__] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded__) {
            fetchData();
            setIsloaded__(true);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded__]);

  const toggleExpanded = (index) => {
    setExpandedList((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  useEffect(() => {
    const id = setInterval(() => {
      setservicedeatilsdata((prevState) => ({
        ...prevState,
        image1: prevState?.image2, // Swap image1 with image2
        image2: prevState?.image1, // Swap image2 with image1
      }));
    }, 10000);

   
    return () => clearInterval(id);
  }, []);

  return (
    <div ref={componentRef}>
                              <MetaProvider title={servicedetailsdata?.heading1 } description={servicedetailsdata?.description}/>

      <div className="page-content bg-white">
        {/* inner page banner */}
        {servicedetailsdata ? (
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: `url(${servicedetailsdata?.image1})` }}
          >
            <PageTitle
              motherMenu={servicedetailsdata?.heading}
              activeMenu={servicedetailsdata?.description}
            />
          </div>
        ) : (
          <Skeleton height={300} baseColor="#556B2F" />
        )}

        {/* inner page banner END */}
        <div className="content-block">
          {/* About Us */}
          <div className="section-full content-inner exp-services">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-head text-black text-center">
                    <h4 className="text-gray-dark font-weight-500 m-b10">
                      {servicedetailsdata?.heading1 || <Skeleton width={150} />}
                    </h4>
                    <h2 className="box-title m-tb0">
                      {servicedetailsdata?.subheading1 || (
                        <Skeleton width={600} />
                      )}
                      <span className="bg-primary"></span>
                    </h2>
                    <p className="font-weight-300 font-18">
                      {servicedetailsdata?.description1 || (
                        <Skeleton count={5} />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Our Services */}
          {/* About Us */}
          <div className="section-full">
            <div className="container-fluid">
              <div className="row dzseth">
                <div className="col-lg-6 col-md-12 p-a0 img-fix">
                  {!servicedetailsdata ? (
                    <Skeleton height={700} />
                  ) : (
                    <img
                      src={servicedetailsdata?.image2}
                      alt=""
                      className="img-cover"
                    />
                  )}
                </div>
                <div className="col-lg-6 col-md-12 bg-black-light p-lr0 dis-tbl">
                  <div className="p-a30 dis-tbl-cell ">
                    <div className="max-w500 m-auto p-tb30">
                      <div className="row">
                        <div className="col-lg-12 text-white">
                          <h3 className="box-title m-b10">
                            {servicedetailsdata?.heading2 || <Skeleton />}
                            <span className="bg-primary"></span>
                          </h3>
                          <h5 className="m-b30">
                            {servicedetailsdata?.subheading2 || (
                              <Skeleton count={2} />
                            )}
                          </h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 m-b30 chosesus-content">
                          <ul className="list-checked primary text-white">
                            <li>
                              <span>
                                {servicedetailsdata?.Point1 || <Skeleton />}
                              </span>
                            </li>
                            <li>
                              <span>
                                {servicedetailsdata?.Point2 || <Skeleton />}
                              </span>
                            </li>
                            <li>
                              <span>
                                {servicedetailsdata?.Point3 || <Skeleton />}
                              </span>
                            </li>
                          </ul>
                          <p>
                            {servicedetailsdata?.description2 || (
                              <Skeleton count={7} baseColor="#696969" />
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About Us End */}
          {/* About Us */}
          <div className="section-full content-inner">
            <div className="container">
              <div className="section-head text-black text-center">
                <h4 className="text-gray-dark m-b10">
                  {servicedetailsdata?.heading3 || <Skeleton width={100} />}
                </h4>
                <h2 className="box-title m-tb0">
                  {servicedetailsdata?.subheading3 || <Skeleton width={400} />}
                  <span className="bg-primary"></span>
                </h2>
                <p>
                  {servicedetailsdata?.description3 || <Skeleton count={4} />}
                </p>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {!servicedetailsdata
                  ? [...Array(6)].map((_, index) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-6 m-b30"
                        key={index}
                      >
                        <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
                          <div className="icon-content">
                            <h4 className="dlab-tilte text-uppercase"></h4>
                            <p>
                              <Skeleton height={150} />
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  : servicedetailsdata?.CardData?.map((data, index) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-6 m-b30"
                        key={index}
                      >
                        <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
                          <div className="icon-content">
                            <h4 className="dlab-tilte text-uppercase">
                              <Link to={"#"}>{data?.heading}</Link>
                            </h4>
                            <p>
                              {expandedList[index]
                                ? data.description
                                : `${data.description.slice(0, 100)}...`}
                            </p>
                            <button
                              className="site-button radius-xl outline outline-2"
                              onClick={() => toggleExpanded(index)}
                            >
                              {" "}
                              {expandedList[index] ? "Read Less" : "Read More"}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
          {/* Our Services */}
          {/* Why Chose Us */}
          <SectionCounter />
          {/* Why Chose Us End */}
          {/* Testimonials */}
          <div
            className="section-full content-inner"
            style={{
              backgroundImage: `url(${bgmap})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="container">
              <div className="section-content m-b30 mt-5 ">
                <TestimonialCarousel />
              </div>
            </div>
          </div>
          {/* Testimonials END */}
          {/* Get in touch */}

          <FormStyle />

          {/* Get in touch */}
        </div>
        {/* contact area END */}
      </div>
    </div>
  );
});

export default ServicesDetails;
