import React, { Suspense, lazy } from 'react';

const Header = lazy(() => import("./../Layout/Header1"))
const Teammember = lazy(() => import("../Element/TeamMember"))
const Footer = lazy(() => import("./../Layout/Footer1"))

const Team = () => {

    return (
        <>
            <Suspense fallback={<div className="loader-container">
                <div className="loader"></div>
            </div>}>
               

                <Teammember />

               
            </Suspense>

        </>
    )
}



export default Team;


