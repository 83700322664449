import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Counter from './Counter';
import axios from 'axios';
import { apiurl } from '../../api handler/apiurl';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
const SectionCounter = () => {
	const [servicebeltdata, setserivebeltdata] = useState(null);

	const ServiceBelt = async () => {
		const res = await axios({
			method: "POST",
			url: `${apiurl}services/serviceBelt/getAll/getAllServiceBelt`,
			headers: {
				'Accept': 'application/json',
				'content-Type': "application/json"
			}

		})
		setserivebeltdata(res?.data?.data[0]);

	}
	const [isLoaded__, setIsloaded__] = useState(false)
	const componentRef = useRef()

	useEffect(() => {

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {

				if (entry.isIntersecting && !isLoaded__) {
					ServiceBelt()
					setIsloaded__(true)
				}

			})
		}, { threshold: 0.1 })
		if (componentRef.current) {
			observer.observe(componentRef.current)
		}

		return () => observer.disconnect();

	}, [isLoaded__])




	return (
		<div ref={componentRef}>


			<div className="section-full content-inner-1 overlay-primary about-service bg-img-fix" style={{ backgroundImage: `url(${servicebeltdata?.image})` }}>
				<div className="container">
					<div className="section-head text-white text-center">
						<h2 className="box-title m-tb0 max-w800 m-auto">{servicebeltdata?.heading || <Skeleton />}<span className="bg-primary"></span></h2>
						<p>{servicebeltdata?.subHeading || <Skeleton />}</p>
					</div>
				</div>
				{servicebeltdata ? (
					<div className="container">
						<div className="row text-white">
							<div className="col-lg-4 col-md-4 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a30 center">
									<div className="icon-lg text-white m-b20">
										<Link to={"#"} className="icon-cell text-white"></Link>
										<i className={`${servicebeltdata?.icon1}`}></i>
									</div>
									<div className="icon-content">
										<h5 className="dlab-tilte text-uppercase">{servicebeltdata?.title1}</h5>
										<p>{servicebeltdata?.desc1}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a30 center">
									<div className="icon-lg text-white m-b20">
										<Link to={"#"} className="icon-cell text-white"></Link>
										<i className={`${servicebeltdata?.icon2}`}></i>
									</div>
									<div className="icon-content">
										<h5 className="dlab-tilte text-uppercase">{servicebeltdata?.title2}</h5>
										<p>{servicebeltdata?.desc2}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a30 center">
									<div className="icon-lg text-white m-b20">
										<Link to={"#"} className="icon-cell text-white"></Link>
										<i className={`${servicebeltdata?.icon3}`}></i>
									</div>
									<div className="icon-content">
										<h5 className="dlab-tilte text-uppercase">{servicebeltdata?.title3}</h5>
										<p>{servicebeltdata?.desc3}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="container">
						<div className="row text-white">
							{[...Array(3)].map((_, index) => (
								<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
									<div className="icon-bx-wraper bx-style-1 p-a30 center">
										<div className="icon-lg text-white m-b20">
											<Skeleton height={50} />
										</div>
										<div className="icon-content">
											<h5 className="dlab-tilte text-uppercase"><Skeleton /></h5>
											<p><Skeleton count={3} /></p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				)}


				<div className="choses-info text-white">
					<Counter />
				</div>
			</div>
		</div>

	);
};

export default SectionCounter;
