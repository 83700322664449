import AWS from 'aws-sdk';

// async function convertFileToBlobFilePath(file) {
//     if (!file) {
//         throw new Error('No file provided.');
//     }

//     const reader = new FileReader();

//     const fileReadPromise = new Promise((resolve, reject) => {
//         reader.onload = (event) => {
//             const blob = new Blob([event.target.result], { type: file.type });

//             // Create a temporary anchor element to obtain the blob's filepath
//             const temporaryAnchor = document.createElement('a');
//             temporaryAnchor.href = URL.createObjectURL(blob);
//             const filePath = temporaryAnchor.href;

//             // Clean up the temporary anchor element and the Blob object
//             URL.revokeObjectURL(temporaryAnchor.href);

//             resolve(filePath);
//         };

//         reader.onerror = () => {
//             reject(new Error('Error reading the file.'));
//         };

//         reader.readAsArrayBuffer(file);
//     });

//     try {
//         const filePath = await fileReadPromise;
//         return filePath;
//     } catch (error) {
//         throw error;
//     }
// }

// Usage example with async/await:
// async function handleFileConversion(selectedFile) {
//     try {
//         const filePath = await convertFileToBlobFilePath(selectedFile);
//         console.log('Filepath:', filePath);
//         return filePath;
//     } catch (error) {
//         console.error(error.message);
//     }
// }

export async function UploadPdf(filepath) {
    const file_name = Math.random().toString(36).substring(6) + '_uploads.pdf';

    const config = {
        endpoint: new AWS.Endpoint('blr1.digitaloceanspaces.com'),
        accessKeyId: 'DO002LEBDZP7CX647E47',
        secretAccessKey: '+7A2cDpxBhzPAo9OY+TCvLNLeVFzQInTY/dKZzBd+tc',
        signatureVersion: 'v4',
    };

    const s3 = new AWS.S3(config);

    try {
        const params = {
            Bucket: 'esevabemc',
            Key: file_name,
            Body: filepath,
            ContentType: 'application/pdf',
            ACL: 'public-read',
        };

        await s3.upload(params).promise();

        const fileUrl = `https://${config.endpoint.host}/${params.Bucket}/${params.Key}`;
        console.log("fileurl==", fileUrl)
        return fileUrl;
    } catch (error) {
        console.error('Error uploading the file:', error);
        return null;
    }
}

export async function UploadToAWS(file) {
    try {
        const a = await UploadPdf(file);
        console.log({ a })
        return a;
    } catch (error) {
        console.log(error);
    }
}

export async function UploadToAWSForOffline(file) {
    try {
        AWS.config.update({
            endpoint: new AWS.Endpoint('blr1.digitaloceanspaces.com'),
            accessKeyId: 'DO002LEBDZP7CX647E47',
            secretAccessKey: '+7A2cDpxBhzPAo9OY+TCvLNLeVFzQInTY/dKZzBd+tc',
            signatureVersion: 'v4',
            region: 'ap-south-1',
        });

        const s3 = new AWS.S3();

        const params = {
            Bucket: 'esevabemc',
            Key: file.name,
            Body: file,
            ContentType: 'application/pdf',
            ACL: 'public-read',
        };
        const uploadedObject = await s3.upload(params).promise();
        const pdfUrl = uploadedObject.Location;
        console.log({ pdfUrl })
        return pdfUrl;
    } catch (error) {
        console.log(error);
    }
}
