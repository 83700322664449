import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import Sidebar from './../../Element/Sidebar';
import bnr from './../../../images/banner/bnr5.jpg';
import thum1 from './../../../images/blog/default/thum1.jpg';
import grid4 from './../../../images/blog/grid/pic4.jpg';
import grid1 from './../../../images/blog/grid/pic1.jpg';
import test1 from './../../../images/testimonials/pic1.jpg';
import test2 from './../../../images/testimonials/pic2.jpg';
import test3 from './../../../images/testimonials/pic3.jpg';
import { useToasts } from 'react-toast-notifications';
import { UploadToAWS } from '../../../uploadfile/uploadpdf';
import { apiurl } from '../../../api handler/apiurl';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import MetaProvider from '../../Helmet';
const BlogDetails = () => {

    function formatDate(dateString) {
        const date = new Date(dateString);
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = date.getDate().toString().padStart(2, '0');
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const formattedDate = `${day}  ${month.toUpperCase()} ${year}`;

        return formattedDate;
    }
    const [blogdetails, setblogdetails] = useState(null)
    const [Name, setName] = useState("")
    const [phone, Setphone] = useState("")
    const [Email, setEmail] = useState("")
    const [fileinfo, setfileinfo] = useState("")
    const [idea, setidea] = useState("")
    const [error, setError] = useState({})
    const { addToast } = useToasts();

    const Validate = () => {
        let error = {}
        if (!Name) {
            error.Name = "Name is required"
        }
        if (!phone && !Email) {
            error.PhoneOrEmail = "Either Phone or Email is required";
        }
        if (!fileinfo) {
            error.fileinfo = "Fiel is required";
        }
        if (!idea) {
            error.idea = "This field is required"
        }

        setError(error)

        return error
    }
    const AddClientresponse = async () => {
        const error = Validate()
        if (Object.keys(error).length > 0) {
            return false
        }
        try {

            const res = await axios({
                method: "POST",
                url: `${apiurl}/contactUs/clientres/create/clientresponse`,
                headers: {
                    'Accept': 'application/json',
                    'content-Type': "application/json"
                },
                data: {
                    Name: Name,
                    Phone: phone,
                    EmailId: Email,
                    File: fileinfo,
                    idea: idea


                }
            })


            if (res?.data?.code === 201) {
                setName("")
                Setphone("")
                setEmail("")
                setfileinfo("")
                setidea("")

                addToast('Saved Successfully', { appearance: 'success' });


            }
        } catch (error) {

            console.error("Error adding client response:", error);
        }
    };
    const handelfileChange = async (event) => {
        try {
            event.preventDefault()
            let file = event.target.files[0];

            const responseUrl = await UploadToAWS(file);


            setfileinfo(responseUrl)

        } catch (error) {
            console.log(error)
        }

    }
    const { reciviedUrl } = useParams();

    const getBlogDetailsByUrl = async () => {
        try {
            const res = await axios({
                method: "POST",
                url: `${apiurl}/blog/blog/get/getBlogdetailsbyUrl`,
                headers: {
                    'Accept': 'application/json',
                    'content-Type': "application/json"
                },
                data: {
                    url: reciviedUrl
                }
            })
       console.log(res?.data?.data,"here is blog detils")
            setblogdetails(res?.data?.data)

        } catch (error) {
            console.log(error)
        }

    }
    const fetchData = async () => {
        reciviedUrl && await getBlogDetailsByUrl()
    }

    const [isLoaded__, setIsloaded__] = useState(false)
    const componentRef = useRef()
    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                if (entry.isIntersecting && !isLoaded__) {
                    fetchData();
                    setIsloaded__(true)
                }

            })
        }, { threshold: 0.1 })
        if (componentRef.current) {
            observer.observe(componentRef.current)
        }

        return () => observer.disconnect();

    }, [isLoaded__])

    return (
        <div ref={componentRef}>
            <MetaProvider title={blogdetails?.mainHeading} description={blogdetails?.mainDesc } imageUrl={blogdetails?.topImage}/>

            <div className="page-content bg-white">
                {
                    blogdetails ? (<div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: `url(${blogdetails?.topImage})` }}>
                        <PageTitle motherMenu='Blog Details' activeMenu='Blog Details' />
                    </div>) : (<Skeleton height={400} baseColor='#556B2F' />)
                }

                <div className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-9">
                                <div className="blog-post blog-single">
                                    <div className="dlab-post-title ">
                                        <h4 className="post-title m-t0"><Link to={"#"}>{blogdetails?.mainHeading || <Skeleton width={300} />}</Link></h4>
                                    </div>
                                    <div className="dlab-post-meta m-b20">
                                        <ul className="d-flex align-items-center">
                                            <li className="post-date"> <i className="fa fa-calendar"></i>{blogdetails?.date ? formatDate(blogdetails.date) : <Skeleton width={100} />}</li>
                                            <li className="post-author"><i className="fa fa-user"></i>By&nbsp;<Link to={"#"}>{blogdetails?.by || <Skeleton width={100} />}</Link> </li>
                                        </ul>
                                    </div>
                                    <div className="dlab-post-media dlab-img-effect zoom-slow">
                                        {
                                            blogdetails ? (<Link to={"#"}><img src={blogdetails?.image1} alt="" /></Link>) : (<Skeleton height={300} />)
                                        }

                                    </div>
                                    <div className="dlab-post-text">
                                        <p>{blogdetails?.mainDesc || <Skeleton count={10} />}</p>
                                        <blockquote>
                                            {blogdetails?.quotes || <Skeleton count={3} />}
                                        </blockquote>
                                        <p>
                                            {blogdetails?.description || <Skeleton count={3} />}
                                        </p>
                                        <h5>{blogdetails?.heading1 || <Skeleton width={250} />}</h5>
                                        {
                                            blogdetails && blogdetails.cards ? (
                                                blogdetails.cards.map((item, index) => (
                                                    <div key={index}>
                                                        {index % 2 === 0 ? (
                                                            <>
                                                                <img className="alignleft" width="300" src={item?.image} alt="" />
                                                                <p>{item?.description}</p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="dlab-divider bg-gray-dark"></div>
                                                                <img className="alignright" width="300" src={item?.image} alt="" />
                                                                <p>{item?.description}</p>
                                                            </>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                Array.from({ length: 2 }, (_, index) => (
                                                    <div key={index}>
                                                        <Skeleton height={400} />
                                                    </div>
                                                ))
                                            )
                                        }




                                    </div>
                                    {/* <div className="dlab-post-tags clear">
                                        <div className="post-tags">
                                            <Link to={"#"}>Child </Link>
                                            <Link to={"#"}>Eduction </Link>
                                            <Link to={"#"}>Money </Link>
                                            <Link to={"#"}>Resturent </Link>
                                        </div>
                                    </div>
                                    <div className="dlab-divider bg-gray-dark op4"><i className="icon-dot c-square"></i></div>
                                    <div className="share-details-btn">
                                        <ul>
                                            <li><h5 className="m-a0">Share Post</h5></li>
                                            <li><Link to={"#"} className="site-button facebook button-sm"><i className="fa fa-facebook"></i> Facebook</Link></li>
                                            <li><Link to={"#"} className="site-button google-plus button-sm"><i className="fa fa-google-plus"></i> Google Plus</Link></li>
                                            <li><Link to={"#"} className="site-button linkedin button-sm"><i className="fa fa-linkedin"></i> Linkedin</Link></li>
                                            <li><Link to={"#"} className="site-button instagram button-sm"><i className="fa fa-instagram"></i> Instagram</Link></li>
                                            <li><Link to={"#"} className="site-button twitter button-sm"><i className="fa fa-twitter"></i> Twitter</Link></li>
                                            <li><Link to={"#"} className="site-button whatsapp button-sm"><i className="fa fa-whatsapp"></i> Whatsapp</Link></li>
                                        </ul>
                                    </div> */}
                                </div>

                            </div>
                            <div className="col-lg-4 col-xl-3 sticky-top">
                                <Sidebar />
                                <div>
                                    <form className="inquiry-form inner dzForm">
                                        <div className="dzFormMsg"></div>
                                        <h3 className="box-title m-t0 m-b10">Let's Convert Your Idea into Reality <span className="bg-primary"></span></h3>
                                        <p>we're here to create the future. Your ideas, your vision, they're the fuel for innovation, and we're ready to turn them into reality</p>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="dzName" type="text" className="form-control" placeholder="Name"
                                                            value={Name}
                                                            onChange={(e) => { setName(e.target.value) }}
                                                        />
                                                    </div>
                                                    {error.Name && (<p className="text-danger mt-2">{error.Name}</p>)}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input name="dzOther[Phone]" type="text" className="form-control" placeholder="Phone"
                                                            value={phone}
                                                            onChange={(e) => { Setphone(e.target.value) }}
                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input name="dzEmail" type="email" className="form-control" placeholder="Your Email Id"
                                                            value={Email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {error?.PhoneOrEmail && (<p className="text-danger mt-2">{error.PhoneOrEmail}</p>)}
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-check-box text-primary"></i></span>
                                                        <label className="form-control" style={{ cursor: 'pointer', padding: '10px', borderRadius: '5px', backgroundColor: 'white' }}>
                                                            Upload  Pdf File
                                                            <input
                                                                name="dzOther[Subject]"
                                                                type="file"
                                                                accept=".pdf"
                                                                required
                                                                multiple
                                                                className="form-control"
                                                                placeholder="Upload File"
                                                                onChange={handelfileChange}
                                                                style={{
                                                                    display: 'none'
                                                                }}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                {error.fileinfo && (<p className="text-danger mt-2">{error.fileinfo}</p>)}
                                            </div>
                                            {/* <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-file text-primary"></i></span>
                                                            <input name="dzOther[Subject]" type="text" required className="form-control" placeholder="Upload File" />
                                                        </div>
                                                    </div>
                                                </div> */}
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                        <textarea name="dzMessage" rows="4" className="form-control"
                                                            value={idea}
                                                            onChange={(e) => setidea(e.target.value)}
                                                            placeholder="Tell us about your project or idea"></textarea>
                                                    </div>
                                                </div>
                                                {error.idea && <p className="text-danger">{error.idea}</p>}
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                        <input className="form-control d-none" data-recaptcha="true" required data-error="Please complete the Captcha" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <button name="submit" type="submit" value="Submit" className="site-button button-lg"
                                                    onClick={AddClientresponse}
                                                >

                                                    <span>Send Message</span> </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default BlogDetails;
