import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SidebarCarousel from "./SidebarCarousel";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import { apiurl } from "../../api handler/apiurl";
//Images
import pic1 from "./../../images/blog/recent-blog/pic1.jpg";
import pic2 from "./../../images/blog/recent-blog/pic2.jpg";
import pic3 from "./../../images/blog/recent-blog/pic3.jpg";
import gallery1 from "./../../images/gallery/pic1.jpg";
import gallery2 from "./../../images/gallery/pic2.jpg";
import gallery3 from "./../../images/gallery/pic3.jpg";
import gallery4 from "./../../images/gallery/pic4.jpg";
import gallery5 from "./../../images/gallery/pic5.jpg";
import gallery7 from "./../../images/gallery/pic7.jpg";
import gallery8 from "./../../images/gallery/pic8.jpg";
import gallery9 from "./../../images/gallery/pic9.jpg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Sidebar = () => {
  const widgetPost = [{ image: pic1 }, { image: pic2 }, { image: pic3 }];
  const postThum = [
    { images: gallery1 },
    { images: gallery2 },
    { images: gallery3 },
    { images: gallery4 },
    { images: gallery5 },
    { images: gallery7 },
    { images: gallery8 },
    { images: gallery9 },
  ];
  const [allblogcard, setallblogcard] = useState([]);
  const [ourserviceImage, setourserviceImage] = useState([]);
  const blogDetails = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${apiurl}/blog/blog/getAll/getAllBlogCard`,
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
        },
      });

      setallblogcard(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchServiceDetails = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${apiurl}/services/serviCard/getAll/getAllServiceCard`,
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
        },
      });

      setourserviceImage(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchData = async () => {
    await blogDetails();
    await fetchServiceDetails();
  };
  const [isLoaded__, setIsloaded__] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded__) {
            fetchData();
            setIsloaded__(true);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded__]);

  return (
    <div ref={componentRef}>
      <aside className="side-bar">
        <div className="widget">
          <h5 className="widget-title style-1">Search</h5>
          <div className="search-bx style-1">
            <form role="search" method="post">
              <div className="input-group">
                <input
                  name="text"
                  className="form-control"
                  placeholder="Enter your keywords..."
                  type="text"
                />
                <span className="input-group-btn">
                  <button
                    type="submit"
                    className="fa fa-search text-primary"
                  ></button>
                </span>
              </div>
            </form>
          </div>
        </div>

        <div className="widget recent-posts-entry">
          <div className="widget recent-posts-entry">
            <h5 className="widget-title style-1">Our Service</h5>
            <div className="widget-post-bx">
              <div className="widget-post clearfix">
                <div className="dlab-post-media">
                  {" "}
                  <img
                    src={ourserviceImage[ourserviceImage.length - 1]?.image2}
                    width="200"
                    height="143"
                    alt=""
                  />{" "}
                </div>
                <div className="dlab-post-info">
                  <div className="dlab-post-header">
                    <h6 className="post-title">
                      {allblogcard.length > 0 ? (
                        <Link to={"#"}>
                          {ourserviceImage[ourserviceImage.length - 1]?.heading}
                        </Link>
                      ) : (
                        <Skeleton />
                      )}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="widget-post clearfix">
                <div className="dlab-post-media">
                  {" "}
                  <img
                    src={ourserviceImage[ourserviceImage.length - 2]?.image2}
                    width="200"
                    height="143"
                    alt=""
                  />{" "}
                </div>
                <div className="dlab-post-info">
                  <div className="dlab-post-header">
                    <h6 className="post-title">
                      {
                        <Link to={"#"}>
                          {ourserviceImage[ourserviceImage.length - 2]?.heading}
                        </Link>
                      }
                    </h6>
                  </div>
                </div>
              </div>
              <div className="widget-post clearfix">
                <div className="dlab-post-media">
                  {" "}
                  <img
                    src={ourserviceImage[ourserviceImage.length - 3]?.image2}
                    width="200"
                    height="143"
                    alt=""
                  />{" "}
                </div>
                <div className="dlab-post-info">
                  <div className="dlab-post-header">
                    <h6 className="post-title">
                      {
                        <Link to={"#"}>
                          {ourserviceImage[ourserviceImage.length - 3]?.heading}
                        </Link>
                      }
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="widget widget-project">
            <h5 className="widget-title style-1">Our Portfolio</h5>
            <SidebarCarousel />
          </div>
          <h5 className="widget-title style-1">Blogs</h5>
          <div className="widget-post-bx">
            <div className="widget-post clearfix">
              <div className="dlab-post-media">
                {" "}
                <img
                  src={allblogcard[allblogcard.length - 1]?.mainImage}
                  width="200"
                  height="143"
                  alt=""
                />{" "}
              </div>
              <div className="dlab-post-info">
                <div className="dlab-post-header">
                  <h6 className="post-title">
                    {allblogcard.length > 0 ? (
                      <Link to={"#"}>
                        {allblogcard[allblogcard.length - 1]?.mainHeading}
                      </Link>
                    ) : (
                      <Skeleton />
                    )}
                  </h6>
                </div>
                <div className="dlab-post-meta">
                  <ul>
                    <li className="post-author">
                      {allblogcard[allblogcard.length - 1]?.by || <Skeleton />}
                    </li>
                    {/* <li className="post-comment"><i className="fa fa-comments"></i> 28</li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="widget-post clearfix">
              <div className="dlab-post-media">
                {" "}
                <img
                  src={allblogcard[allblogcard.length - 2]?.mainImage}
                  width="200"
                  height="143"
                  alt=""
                />{" "}
              </div>
              <div className="dlab-post-info">
                <div className="dlab-post-header">
                  <h6 className="post-title">
                    {
                      <Link to={"#"}>
                        {allblogcard[allblogcard.length - 2]?.mainHeading}
                      </Link>
                    }
                  </h6>
                </div>
                <div className="dlab-post-meta">
                  <ul>
                    <li className="post-author">
                      {allblogcard[allblogcard.length - 2]?.by || <Skeleton />}
                    </li>
                    {/* <li className="post-comment"><i className="fa fa-comments"></i> 28</li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="widget-post clearfix">
              <div className="dlab-post-media">
                {" "}
                <img
                  src={allblogcard[allblogcard.length - 3]?.mainImage}
                  width="200"
                  height="143"
                  alt=""
                />{" "}
              </div>
              <div className="dlab-post-info">
                <div className="dlab-post-header">
                  <h6 className="post-title">
                    {
                      <Link to={"#"}>
                        {allblogcard[allblogcard.length - 3]?.mainHeading}
                      </Link>
                    }
                  </h6>
                </div>
                <div className="dlab-post-meta">
                  <ul>
                    <li className="post-author">
                      {allblogcard[allblogcard.length - 3]?.by || <Skeleton />}
                    </li>
                    {/* <li className="post-comment"><i className="fa fa-comments"></i> 28</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
