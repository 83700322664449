import { Suspense, lazy } from "react";
import React, { Component, useEffect, useState } from "react";

import bgimg2 from "./../../images/background/bg-map.jpg";
import { apiurl } from "../../api handler/apiurl";
import "../../css/Loader.css";
import axios from "axios";
import MetaProvider from "../Helmet";
const Header = lazy(() => import("./../Layout/Header1"));
const Footer = lazy(() => import("./../Layout/Footer1"));
const HomeOwlSlider2 = lazy(() => import("./../Element/HomeOwlSlider2"));
const HomeOwlSlider = lazy(() => import("./../Element/HomeOwlSlider"));
const HomeTab = lazy(() => import("./../Element/HomeTab"));
const PortfolioCarousel = lazy(() => import("./../Element/PortfolioCarousel"));
const TestimonialCarousel = lazy(() =>
  import("./../Element/TestimonialCarousel")
);
const HomeAboutus = lazy(() => import("./../Element/HomeAboutus"));
const FormStyle = lazy(() => import("./../Element/FormStyle"));
const ImgCarouselContent = lazy(() =>
  import("./../Element/ImgCarouselContent")
);

const Homepage = () => {
  return (
    <>
    <MetaProvider/>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        }
      >
        <div className="page-content bg-white">
          <div className="owl-slider-banner main-slider">
            <HomeOwlSlider />
            {/*  Service   */}
            <div className="service-box-slide">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <ImgCarouselContent />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block">
            <HomeAboutus />
            {/* <!-- About Us End --> */}

            {/* <!-- Our Services --> */}
            <div className="section-full content-inner-2">
              <HomeOwlSlider2 />
            </div>

            <HomeTab />

            <div className="section-full content-inner-1 mfp-gallery">
              <PortfolioCarousel />
            </div>

            <div
              className="section-full content-inner"
              style={{
                backgroundImage: "url(" + bgimg2 + ")",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <TestimonialCarousel />
            </div>

            <FormStyle />
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default Homepage;
