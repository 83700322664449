import { Suspense, lazy } from 'react';
import React, { useEffect, useState } from 'react';
import '../../../css/Loader.css'
import MetaProvider from '../../Helmet';
const Header = lazy(() => import("./../../Layout/Header1"))
const Footer=lazy(()=>import("./../../Layout/Footer1"))
const ServiecPageTop=lazy(()=>import("./servicepageTop"))
const Service = () => {
  return (
    <>
    <MetaProvider/>
    <div className="page-wraper">

      <Suspense fallback={<div className="loader-container">
        <div className="loader"></div>
      </div>}>
        <div className="page-content bg-white">
          <ServiecPageTop />
        </div>

      </Suspense>
    </div>
    </>
  );
}

export default Service;
