import React from 'react';

import PageTitle from './../../Layout/PageTitle'; // Update the path as needed
import bnr1 from './../../../images/banner/bnr1.jpg'; // Make sure to import your image correctly

function TermsAndConditions() {
    return (
        <>
          

            <div className="page-content bg-white">
                {/* <!-- inner page banner --> */}
                <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                    <PageTitle motherMenu='TERMS AND CONDITIONS' activeMenu='TERMS AND CONDITIONS' />
                </div>
                {/* <!-- inner page banner END --> */}
                <div className="content-area">
                    <div className="container terms-container">
                        <h2 className="terms-heading">Terms and Conditions</h2>
                        <div className="terms-content">
                            <div className="section">
                                <h3>1. Scope of Services</h3>
                                <p>These terms and conditions govern the provision of IT consultancy and services by TECEADS SOLUTIONS PRIVATE LIMITED to its clients. By engaging our services, you agree to abide by these terms and conditions.</p>
                            </div>
                            <div className="mt-3">
                                <h3>2. Service Agreement</h3>
                                <p>Before commencing any project, a formal service agreement will be established between TECEADS SOLUTIONS PRIVATE LIMITED and the client. The agreement will outline the scope of work, project timeline, deliverables, and payment terms.</p>
                            </div>
                            <div className="mt-3">
                                <h3>3. Client Responsibilities</h3>
                                <p>The client agrees to provide all necessary information, access, and cooperation required for the successful completion of the project. Any delays or hindrances caused by the client may affect project timelines and outcomes.</p>
                            </div>
                            <div className="mt-3">
                                <h3>4. Confidentiality</h3>
                                <p>TECEADS SOLUTIONS PRIVATE LIMITED acknowledges the confidential nature of the client's information and agrees to maintain confidentiality at all times. Information shared by the client will only be used for the purpose of providing the agreed-upon services.</p>
                            </div>
                            <div className="mt-3">
                                <h3>5. Intellectual Property</h3>
                                <p>All intellectual property rights developed or created during the course of the project will belong to the client upon full payment of fees.TECEADS SOLUTIONS PRIVATE LIMITED retains the right to use project-related materials for portfolio and promotional purposes.</p>
                            </div>
                            <div className="mt-3">
                                <h3>6. Payment Terms</h3>
                                <p>Payment terms will be outlined in the service agreement. Clients are required to adhere to the agreed-upon payment schedule. Failure to make timely payments may result in project delays or termination.</p>
                            </div>
                            <div className="mt-3">
                                <h3>7. Limitation of Liability</h3>
                                <p>TECEADS SOLUTIONS PRIVATE LIMITED will not be liable for any direct or indirect damages, including but not limited to loss of profits, arising from the use or inability to use our services. The client agrees to indemnify TECEADS SOLUTIONS PRIVATE LIMITED against any claims or liabilities arising from the use of our services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    );
}

export default TermsAndConditions;
