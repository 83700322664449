import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
// import Header from '../../Layout/Header1';
// import Footer from '../../Layout/Footer1';
import Pagination from '../../../utils/pagination';
import PageTitle from '../../Layout/PageTitle';
import Sidebar from '../../Element/Sidebar';
import { apiurl } from '../../../api handler/apiurl';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import bnr1 from './../../../images/banner/bnr4.jpg';
import grid1 from './../../../images/blog/grid/pic1.jpg';
import grid2 from './../../../images/blog/grid/pic2.jpg';
import grid3 from './../../../images/blog/grid/pic3.jpg';
import grid4 from './../../../images/blog/grid/pic4.jpg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation } from 'react-router-dom'
import MetaProvider from '../../Helmet';
const blogPost = [
	{ images: grid1, }, { images: grid2, }, { images: grid3, },
	{ images: grid1, }, { images: grid2, }, { images: grid3, },
	{ images: grid4, }, { images: grid1, },
];

const Blog = () => {
	const [blogimageTop, setblogimageTop] = useState(null)

	const blogTopImage = async () => {
		try {
			const res = await axios({
				method: "POST",
				url: `${apiurl}blog/blog/getAll/getAllBlogTop`,
				headers: {
					'Accept': 'application/json',
					'content-Type': "application/json"
				},


			})

			setblogimageTop(res?.data?.data[0].image)

		} catch (error) {
			console.log(error)
		}

	}
	const fetchData = async () => {
		await blogTopImage()

	}



	const [isLoaded__, setIsloaded__] = useState(false)
	const componentRef = useRef()
	useEffect(() => {

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {

				if (entry.isIntersecting && !isLoaded__) {
					fetchData();
					setIsloaded__(true)
				}

			})
		}, { threshold: 0.1 })
		if (componentRef.current) {
			observer.observe(componentRef.current)
		}

		return () => observer.disconnect();

	}, [isLoaded__])

	return (
		<div ref={componentRef}>
			<MetaProvider title={"Blogs : Teceads Solutions"}/>
			<div className="page-content bg-white">
				{
					blogimageTop ? (
						<div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: `url(${blogimageTop})` }}>
							<PageTitle motherMenu='Blog' activeMenu='Blog' />
						</div>

					) : (
						<Skeleton height={400} baseColor='#556B2F' />
					)
				}


				<div className="content-area">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 sticky-top">
								<Sidebar />
							</div>
							<ListMainBlog />
						</div>
					</div>
				</div>
			</div>

		</div>
	);
};

const ListMainBlog = () => {
	const [allblogcard, setallblogcard] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [selectedPageSize, setSelectedPageSize] = useState(6)
	const [totalItemCount, setTotalItemCount] = useState(0);
	const [totalPage, setTotalPage] = useState(1);
	const [searchText, setSearchText] = useState('');
	const history = useHistory();

	function formatDate(dateString) {
		const date = new Date(dateString);
		const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		const day = date.getDate().toString().padStart(2, '0');
		const month = monthNames[date.getMonth()];
		const year = date.getFullYear();
		const formattedDate = `${day}  ${month.toUpperCase()} ${year}`;

		return formattedDate;
	}

	const location = useLocation()
	let searchQuery
	useEffect(() => {

		const searchParams = new URLSearchParams(location.search);
		searchQuery = searchParams.get('search');
		setSearchText(searchQuery);

	}, []);


	const blogDetails = async () => {
		try {

			const res = await axios({
				method: "POST",
				url: `${apiurl}/blog/blog/getAll/getAllBlogCard`,
				headers: {
					'Accept': 'application/json',
					'content-Type': "application/json"

				},

				data: {
					searchText: searchQuery,
					pageSize: selectedPageSize,
					currentPage: currentPage
				}
			})

			setSearchText("");

			if (res?.data?.code === 200) {

				setallblogcard(res?.data?.data)
				setTotalItemCount(res?.data?.totalCount)
				setTotalPage(Math.ceil(res?.data?.totalCount / selectedPageSize))
			}
		} catch (error) {
			console.log(error)
		}



	}
	const handelBlogDetails = (data) => {
		const url = `/blog-details/${data.url.replace("/", "-")}`;
		history.push(url);


	}
	useEffect(() => {

		blogDetails(selectedPageSize, currentPage)
	}, [currentPage])


	const [isLoaded__, setIsloaded__] = useState(false)
	const componentRef = useRef()
	useEffect(() => {

		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {

				if (entry.isIntersecting && !isLoaded__) {

					setIsloaded__(true)
				}

			})
		}, { threshold: 0.1 })
		if (componentRef.current) {
			observer.observe(componentRef.current)
		}

		return () => observer.disconnect();

	}, [isLoaded__])


	return (
		<div ref={componentRef} className="col-lg-9">
			{allblogcard?.length === 0 ? (
				[...Array(5)].map((_, index) => {
					return (
						<div className="blog-post blog-md clearfix" key={index}>
							<div className="dlab-post-media dlab-img-effect zoom-slow">
								<Link to={"#"}><Skeleton height={200} /></Link>
							</div>
							<div className="dlab-post-info">
								<div className="dlab-post-title ">
									<h4 className="post-title"><Link to={"#"}><Skeleton width={400} /></Link></h4>
								</div>
								<div>
									<ul className="d-flex align-items-center">
										<li className="post-date"> <strong><Skeleton width={100} /></strong> </li>
										<li className="post-author"><Link to={"#"}><Skeleton width={200} /></Link> </li>
										<li className="post-comment"><Link to={"#"}><Skeleton width={25} /></Link> </li>
									</ul>
								</div>
								<div className="dlab-post-text">
									<p><Skeleton count={5} /></p>
								</div>

							</div>
						</div>
					);
				})
			) : (
				allblogcard.map((item, index) => (
					<div className="blog-post blog-md clearfix" key={index}>
						<div className="dlab-post-media dlab-img-effect zoom-slow">
							<Link to={"#"}><img src={item.mainImage} alt="" /></Link>
						</div>
						<div className="dlab-post-info">
							<div className="dlab-post-title ">
								<h4 className="post-title"><Link to={"#"}>{item?.mainHeading}</Link></h4>
							</div>
							<div className="dlab-post-meta">
								<ul className="d-flex align-items-center">
									<li className="post-date"> <i className="fa fa-calendar"></i><strong>{formatDate(item?.date)}</strong> </li>
									<li className="post-author"><i className="fa fa-user"></i>By <Link to={"#"}>{item?.by}</Link> </li>
									<li className="post-comment"><i className="fa fa-comments"></i> <Link to={"#"}>0</Link> </li>
								</ul>
							</div>
							<div className="dlab-post-text">
								<p>{item?.mainDesc.length > 100 ? item?.mainDesc.slice(0, 150) + '...' : item?.mainDesc}</p>
							</div>
							<div className="dlab-post-readmore blog-share">
								<button to={"#"} title="READ MORE" rel="bookmark" onClick={() => { handelBlogDetails(item) }} className="site-button outline outline-1">READ MORE
									<i className="fa fa-long-arrow-right"></i>
								</button>
								<div className="share-btn">
									<ul className="clearfix">
										<li><Link to={"#"} className="site-button sharp"><i className="fa fa-facebook"></i></Link></li>
										<li><Link to={"#"} className="site-button sharp"><i className="fa fa-google-plus"></i></Link></li>
										<li><Link to={"#"} className="site-button sharp"><i className="fa fa-linkedin"></i></Link></li>
										<li><Link to={"#"} className="site-button sharp"><i className="fa fa-twitter"></i></Link></li>
										<li className="share-button"><Link to={"#"} className="site-button sharp"><i className="fa fa-share-alt"></i></Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				))
			)}
			<Pagination
				currentPage={currentPage}
				totalPage={totalPage}
				onChangePage={i => setCurrentPage(i)}
			/>
		</div>

	);
};
export { ListMainBlog };

export default Blog;

