import React, { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import { apiurl } from "../../api handler/apiurl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useToasts } from "react-toast-notifications";
import { UploadToAWS } from "../../uploadfile/uploadpdf";
const FormStyle = React.memo(() => {
  const [homepagefooter1, sethomepagefooter1] = useState("");
  const [Name, setName] = useState("");
  const [phone, Setphone] = useState("");
  const [Email, setEmail] = useState("");
  const [fileinfo, setfileinfo] = useState("");
  const [idea, setidea] = useState("");
  const { addToast } = useToasts();
  const [error, setError] = useState({});
  const [fileName, setFileName] = useState("");

  const Validate = () => {
    let error = {};
    if (!Name) {
      error.Name = "Name is required";
    }
    if (!phone && !Email) {
      error.PhoneOrEmail = "Either Phone or Email is required";
    } else if (!Email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      error.PhoneOrEmail = "It is an Invalid Email";
    }
    if (!fileinfo) {
      error.fileinfo = "File is required";
    }
    if (!idea) {
      error.idea = "This field is required";
    }

    setError(error);

    return error;
  };
  const fetchHoomfooter1 = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${apiurl}home/homepagefooter1/getAll/getAllhomeopagefooter1`,
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
        },
      });

      sethomepagefooter1(res?.data?.data[0]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const fun = async () => {
    await fetchHoomfooter1();
  };

  const AddClientresponse = async () => {
    const error = Validate();
    if (Object.keys(error).length > 0) {
      return false;
    }
    try {
      const res = await axios({
        method: "POST",
        url: `${apiurl}/contactUs/clientres/create/clientresponse`,
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
        },
        data: {
          Name: Name,
          Phone: phone,
          EmailId: Email,
          File: fileinfo,
          idea: idea,
        },
      });

      if (res?.data?.code == 201) {
        setName("");
        Setphone("");
        setEmail("");
        setfileinfo("");
        setidea("");

        addToast("Saved Successfully", { appearance: "success" });
      }
    } catch (error) {
      addToast("Something Went To wrong Please try again", {
        appearance: "danger",
      });
      console.error("Error adding client response:", error);
    }
  };

  const handelfileChange = async (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }

    const responseUrl = await UploadToAWS(file);

    setfileinfo(responseUrl);
  };

  const [isLoaded__, setIsloaded__] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded__) {
            fun();
            setIsloaded__(true);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded__]);
  return (
    <div
      ref={componentRef}
      className="section-full overlay-primary-dark bg-img-fix"
      style={{ backgroundImage: `url(${homepagefooter1?.image})` }}
    >
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 content-inner chosesus-content text-white">
              <h2 className="box-title m-b15 ">
                {homepagefooter1?.heading || <Skeleton width={250} />}
                <span className="bg-primary"></span>
              </h2>
              <p className="font-16 op8">
                {homepagefooter1?.description || <Skeleton count={2} />}
              </p>
              <h3 className="font-weight-500 m-b50 op6 ">
                {homepagefooter1?.Subheading || (
                  <Skeleton count={3} height={20} />
                )}
              </h3>
              <h4 className="">
                {homepagefooter1?.heading2 || <Skeleton width={180} />}
              </h4>
              <ul className="list-checked primary ">
                <li>
                  <span>
                    {homepagefooter1?.Point1 || (
                      <Skeleton baseColor="#FFF8DC" />
                    )}
                  </span>
                </li>
                <li>
                  <span>
                    {homepagefooter1?.Point2 || (
                      <Skeleton baseColor="#FFF8DC" />
                    )}
                  </span>
                </li>
                <li>
                  <span>
                    {homepagefooter1?.Point3 || (
                      <Skeleton baseColor="#FFF8DC" />
                    )}
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-lg-7 col-md-7 m-b30">
              <form className="inquiry-form  dzForm">
                <div className="dzFormMsg"></div>
                <h3 className="box-title m-t0 m-b10">
                  Let's Convert Your Idea into Reality{" "}
                  <span className="bg-primary"></span>
                </h3>
                <p>
                  we're here to create the future. Your ideas, your vision,
                  they're the fuel for innovation, and we're ready to turn them
                  into reality
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="ti-user text-primary"></i>
                        </span>
                        <input
                          name="dzName"
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={Name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {error.Name && (
                      <p className="text-danger mt-2 ms-2">{error.Name}</p>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="ti-mobile text-primary"></i>
                        </span>
                        <input
                          name="dzOther[Phone]"
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          value={phone}
                          onChange={(e) => {
                            Setphone(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="ti-email text-primary"></i>
                        </span>
                        <input
                          name="dzEmail"
                          className="form-control"
                          placeholder="Your Email Id"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    {error?.PhoneOrEmail && (
                      <p className="text-danger mt-2 ms-2">
                        {error.PhoneOrEmail}
                      </p>
                    )}
                  </div>
                  {/* <div className="col-lg-6">
									<div className="form-group">
										<div className="input-group">
											<span className="input-group-addon"><i className="ti-check-box text-primary"></i></span>
											<input name="dzOther[Subject]" type="text" required className="form-control" placeholder="Upload File" />
										</div>
									</div>
								</div> */}
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="ti-file text-primary"></i>
                        </span>
                        <label
                          className="form-control"
                          style={{
                            cursor: "pointer",
                            padding: "10px",
                            borderRadius: "5px",
                            backgroundColor: "white",
                          }}
                        >
                          {fileName ? fileName : "Upload  Pdf File"}
                          <input
                            name="dzOther[Subject]"
                            type="file"
                            accept="application/pdf"
                            required
                            multiple
                            className="form-control"
                            placeholder="Upload File"
                            onChange={handelfileChange}
                            style={{
                              display: "none",
                            }}
                          />
                        </label>
                      </div>
                      {error.fileinfo && (
                        <p className="text-danger mt-2 ms-1">
                          {error.fileinfo}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="ti-agenda text-primary"></i>
                        </span>
                        <textarea
                          name="dzMessage"
                          rows="4"
                          className="form-control"
                          placeholder="Tell us about your project or idea"
                          value={idea}
                          onChange={(e) => {
                            setidea(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                    {error.idea && (
                      <p className="text-danger ms-1">{error.idea}</p>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="input-group">
                        <div
                          className="g-recaptcha"
                          data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                          data-callback="verifyRecaptchaCallback"
                          data-expired-callback="expiredRecaptchaCallback"
                        ></div>
                        <input
                          className="form-control d-none"
                          data-recaptcha="true"
                          required
                          data-error="Please complete the Captcha"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      className="site-button button-lg"
                      onClick={AddClientresponse}
                    >
                      {" "}
                      <span>Send Message</span>{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
});

export default FormStyle;
