import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header1';
import Footer from '../Layout/Footer1';
import PageTitle from '../Layout/PageTitle';
import TestimonialCarousel from '../Element/TestimonialCarousel';
import FormStyle from '../Element/FormStyle';
import axios from 'axios';
import { apiurl } from '../../api handler/apiurl';
import { useLocation } from 'react-router-dom';
import bgmap from '../../images/background/bg-map.jpg';
import '../../css/Loader.css'
import Productbelt from '../Element/productbelt';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import MetaProvider from '../Helmet';

const Productdetails = React.memo(() => {
      const history=useHistory()

    const [porductdeatilsdata, setporductdeatils] = useState("")
    const [expandedList, setExpandedList] = useState([]);
    const { productId } = useParams();
  

    const fetchProductDeatilsByUrl=async(url)=>{
        try{
            const res=await axios({
                method:"POST",
                url:`${apiurl}/products/productcard/get/getproductdetailsByUrl/${url}`,
                headers: {
                    'Accept': 'application/json',
                    'content-Type': "application/json"
                },
    
            })
            setporductdeatils(res?.data?.data);
            setExpandedList(new Array(res?.data?.data?.CardData?.length).fill(false));
        }
        catch(error)
        {
             console.log(error)
            history.push('/our-products');
        }
       
       
    }
   

    const fetchData = async () => {
       productId && fetchProductDeatilsByUrl(productId)
       
    };
    const [isLoaded__, setIsloaded__] = useState(false)
    const componentRef = useRef()
    useEffect(() => {

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {

                if (entry.isIntersecting && !isLoaded__) {
                    fetchData();
                    setIsloaded__(true)
                }

            })
        }, { threshold: 0.1 })
        if (componentRef.current) {
            observer.observe(componentRef.current)
        }

        return () => observer.disconnect();

    }, [isLoaded__])


    const toggleExpanded = (index) => {
        setExpandedList(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    return (
        <div ref={componentRef}>
        <MetaProvider title={porductdeatilsdata?.heading } description={porductdeatilsdata?.description } imageUrl={porductdeatilsdata?.image1}/>

            <div className="page-content bg-white">
                {/* inner page banner */}
                {porductdeatilsdata?.image1 ? (<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: `url(${porductdeatilsdata?.image1})` }}>
                    <PageTitle motherMenu={porductdeatilsdata?.heading} activeMenu={porductdeatilsdata.description}  />
                </div>) : (
                    <Skeleton height={300} baseColor='#556B2F' />
                )}

                {/* inner page banner END */}
                <div className="content-block">
                    {/* About Us */}
                    <div className="section-full content-inner exp-services">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-head text-black text-center">
                                        <h4 className="text-gray-dark font-weight-500 m-b10">{porductdeatilsdata?.heading1 || <Skeleton width={160} />}</h4>
                                        <h2 className="box-title m-tb0">{porductdeatilsdata?.subheading1 || <Skeleton width={700} />}<span className="bg-primary"></span></h2>
                                        <p className="font-weight-300 font-18">{porductdeatilsdata?.description1 || <Skeleton count={6} />}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Our Services */}
                    {/* About Us */}
                    <div className="section-full">
                        <div className="container-fluid">
                            <div className="row dzseth">
                                <div className="col-lg-6 col-md-12 bg-black-light p-lr0 dis-tbl">
                                    <div className="p-a30 dis-tbl-cell ">
                                        <div className="max-w500 m-auto p-tb30">
                                            <div className="row">
                                                <div className="col-lg-12 text-white">
                                                    <h3 className="box-title m-b10">{porductdeatilsdata?.heading2 || <Skeleton />}<span className="bg-primary"></span></h3>
                                                    <h5 className="m-b30">{porductdeatilsdata?.subheading2 || <Skeleton />}</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 m-b30 chosesus-content">
                                                    <ul className="list-checked primary text-white">
                                                        <li><span>{porductdeatilsdata?.Point1 || <Skeleton />}</span></li>
                                                        <li><span>{porductdeatilsdata?.Point2 || <Skeleton />}</span></li>
                                                        <li><span>{porductdeatilsdata?.Point3 || <Skeleton />}</span></li>
                                                    </ul>
                                                    <p>{porductdeatilsdata?.description2 || <Skeleton count={5} baseColor='#696969' />}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !porductdeatilsdata ? (
                                        <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                            <Skeleton height={720} />
                                        </div>
                                    ) : (
                                        <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                            <img src={porductdeatilsdata?.image2} alt="" className="img-cover" />
                                        </div>
                                    )
                                }


                            </div>
                        </div>
                    </div>
                    {/* About Us End */}
                    {/* About Us */}
                    <div className="section-full content-inner">
                        <div className="container">
                            <div className="section-head text-black text-center">
                                <h4 className="text-gray-dark m-b10">{porductdeatilsdata?.heading3 || <Skeleton width={100} />}</h4>
                                <h2 className="box-title m-tb0">{porductdeatilsdata?.subheading3 || <Skeleton width={500} />}<span className="bg-primary"></span></h2>
                                <p>{porductdeatilsdata?.description3 || <Skeleton count={3} />}</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                {!porductdeatilsdata ? (
                                    [...Array(6)].map((_, index) => (
                                        <div className="col-lg-4 col-md-6 col-sm-6 m-b30" key={index}>
                                            <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
                                                <div className="icon-content">
                                                    <h4 className="dlab-tilte text-uppercase"></h4>
                                                    <p><Skeleton height={150} /></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    porductdeatilsdata?.CardData?.map((data, index) => (
                                        <div className="col-lg-4 col-md-6 col-sm-6 m-b30" key={index}>
                                            <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
                                                <div className="icon-content">
                                                    <h4 className="dlab-tilte text-uppercase"><Link to={"#"}>{data?.heading}</Link></h4>
                                                    <p>{expandedList[index] ? data.description : `${data.description.slice(0, 100)}...`}</p>
                                                    <button className="site-button radius-xl outline outline-2" onClick={() => toggleExpanded(index)}> {expandedList[index] ? 'Read Less' : 'Read More'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))

                                )
                                }
                            </div>
                        </div>
                    </div>
                    {/* Our Services */}
                    {/* Why Chose Us */}
                    <Productbelt />
                    {/* Why Chose Us End */}
                    {/* Testimonials */}
                    <div className="section-full content-inner" style={{ backgroundImage: `url(${bgmap})`, backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                        <div className="container">

                            <div className="section-content m-b30 ">
                                <TestimonialCarousel />
                            </div>
                        </div>
                    </div>
                    {/* Testimonials END */}
                    {/* Get in touch */}

                    <FormStyle />

                    {/* Get in touch */}
                </div>
                {/* contact area END */}
            </div>
           


        </div>
    );
})

export default Productdetails;
