import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Header from "./../Layout/Header1";
import Footer from "./../Layout/Footer1";
import { apiurl } from "../../api handler/apiurl";
import "../../css/Loader.css";
import { UploadToAWS } from "../../uploadfile/uploadpdf";
import Skeleton from "react-loading-skeleton";
import { useToasts } from "react-toast-notifications";
import Form from "react-bootstrap/Form";
import MetaProvider from "../Helmet";

const Contact = () => {
  const [contactUs, setContactUs] = useState(null);
  const [Name, setName] = useState("");
  const [phone, Setphone] = useState("");
  const [Email, setEmail] = useState("");
  const [fileinfo, setfileinfo] = useState("");
  const [idea, setidea] = useState("");
  const [error, setError] = useState({});
  const [fileName, setFileName] = useState("");

  const { addToast } = useToasts();
  const Validate = () => {
    let error = {};
    if (!Name) {
      error.Name = "*Name cannot be empty";
    }
    if (!phone && !Email) {
      error.PhoneOrEmail = "*Either Phone or Email is required";
    } else if (!Email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      error.PhoneOrEmail = "*It is an Invalid Email";
    }
    if (!fileinfo) {
      error.fileinfo = "*File cannot  be  Empty";
    }

    if (!idea) {
      error.idea = "*idea  cannot be Empty";
    }

    setError(error);

    return error;
  };

  const fetchDataContactUs = async () => {
    try {
      const res = await axios.post(
        `${apiurl}contactUs/contactUs/getAll/getAllContactUs`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setContactUs(res?.data?.data[0]);
    } catch (error) {
      console.error("Error fetching data for above belt:", error);
    }
  };
  const AddClientresponse = async () => {
    const error = Validate();
    if (Object.keys(error).length > 0) {
      return false;
    }
    try {
      const res = await axios({
        method: "POST",
        url: `${apiurl}/contactUs/clientres/create/clientresponse`,
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
        },
        data: {
          Name: Name,
          Phone: phone,
          EmailId: Email,
          File: fileinfo,
          idea: idea,
        },
      });
      if (res?.data?.code == 201) {
        setName("");
        Setphone("");
        setEmail("");
        setfileinfo("");
        setidea("");
        setFileName("");

        addToast("Your response is Added Successfully", {
          appearance: "success",
        });
      }
    } catch (error) {
      addToast("Something Went To wrong Please try again", {
        appearance: "danger",
      });
      console.error("Error adding client response:", error);
    }
  };

  const [isLoaded__, setIsloaded__] = useState(false);
  const componentRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoaded__) {
            fetchDataContactUs();
            setIsloaded__(true);
          }
        });
      },
      { threshold: 0.1 }
    );
    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, [isLoaded__]);
  const handelfileChange = async (event) => {
    try {
      event.preventDefault();
      let file = event.target.files[0];
      if (file) {
        setFileName(file.name);
      }

      const responseUrl = await UploadToAWS(file);

      setfileinfo(responseUrl);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div ref={componentRef}>
      <MetaProvider title={"Contact US"} description={"We're here to create the future. Your ideas, your vision, they're the fuel for innovation, and we're ready to turn them into reality"}/>
      <div className="full-section">
        <div
          className="dlab-bnr-inr overlay-primary-dark contact-page"
          style={{ backgroundImage: `url(${contactUs?.image})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-xs-4">
                <div className="row text-white">
                  <div className="col-lg-12 col-md-6 m-b30">
                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                      <div className="icon-content">
                        <h5 className="dlab-tilte">
                          <span className="icon-sm text-primary text-left">
                            <i className="ti-location-pin"></i>
                          </span>
                          Company Address
                        </h5>
                        <p>{contactUs?.address || <Skeleton count={2} />}</p>
                        <h6 className="m-b15 font-weight-400">
                          <i className="ti-alarm-clock"></i> Office Hours
                        </h6>
                        <p className="m-b0">
                          {contactUs?.officeHours || <Skeleton count={2} />}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6 m-b30 ">
                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                      <div className="icon-content">
                        <h5 className="dlab-tilte">
                          <span className="icon-sm text-primary text-left">
                            <i className="ti-email"></i>
                          </span>
                          E-mail
                        </h5>
                        {contactUs === null
                          ? [...Array(2)].map((_, index) => (
                            <p key={index} className="m-b0">
                              <Skeleton />
                            </p>
                          ))
                          : contactUs?.email?.map((email, index) => (
                            <p key={index} className="m-b0">
                              {email}
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6 m-b30 ">
                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                      <div className="icon-content">
                        <h5 className="dlab-tilte">
                          <span className="icon-sm text-primary text-left">
                            <i className="ti-mobile"></i>
                          </span>
                          Phone Numbers
                        </h5>
                        {contactUs === null
                          ? [...Array(2)].map((_, index) => (
                            <p key={index} className="m-b0">
                              <Skeleton />
                            </p>
                          ))
                          : contactUs?.phone?.map((phone, index) => (
                            <p key={index} className="m-b0">
                              +91- {phone}
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-xs-8">
                <form className="inquiry-form dzform">
                  <div className="dzFormMsg"></div>
                  <h3 className="box-title m-t0 m-b10">
                    Let's Convert Your Idea into Reality{" "}
                    <span className="bg-primary"></span>
                  </h3>
                  <p>
                    we're here to create the future. Your ideas, your vision,
                    they're the fuel for innovation, and we're ready to turn
                    them into reality
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="ti-user text-primary"></i>
                          </span>
                          <input
                            name="dzName"
                            type="text"
                            value={Name}
                            className="form-control"
                            placeholder="First Name"
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {error.Name && (
                        <p className="text-danger ms-2">{error.Name}</p>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="ti-mobile text-primary"></i>
                          </span>
                          <input
                            name="dzOther[Phone]"
                            type="text"
                            value={phone}
                            className="form-control"
                            placeholder="Phone"
                            onChange={(e) => {
                              Setphone(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="ti-email text-primary"></i>
                          </span>
                          <input
                            name="dzEmail"
                            type="email"
                            value={Email}
                            className="form-control"
                            placeholder="Your Email Id"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {error?.PhoneOrEmail && (
                      <p className="text-danger" style={{ marginLeft: "15px" }}>{error.PhoneOrEmail}</p>
                    )}
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="ti-file text-primary"></i>
                          </span>
                          <label
                            className="form-control"
                            style={{
                              cursor: "pointer",
                              padding: "10px",
                              borderRadius: "5px",
                              backgroundColor: "white",
                            }}
                          >
                            {fileName ? fileName : "Upload  Pdf File"}
                            <input
                              name="dzOther[Subject]"
                              type="file"
                              accept="application/pdf"
                              multiple
                              className="form-control"
                              placeholder="Upload File"
                              onChange={handelfileChange}
                              style={{
                                display: "none",
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      {error.fileinfo && (
                        <p className="text-danger ms-2">{error.fileinfo}</p>
                      )}
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="ti-agenda text-primary"></i>
                          </span>
                          <textarea
                            name="dzMessage"
                            rows="4"
                            className="form-control"
                            value={idea}
                            placeholder="Tell us about your project or idea"
                            onChange={(e) => {
                              setidea(e.target.value);
                            }}
                          ></textarea>
                        </div>
                        {error.idea && (
                          <p className="text-danger">{error.idea}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <div
                            className="g-recaptcha"
                            data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                            data-callback="verifyRecaptchaCallback"
                            data-expired-callback="expiredRecaptchaCallback"
                          ></div>
                          <input
                            className="form-control d-none"
                            data-recaptcha="true"
                            required
                            data-error="Please complete the Captcha"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="site-button button-lg"
                        onClick={AddClientresponse}
                      >
                        {" "}
                        <span>Send Message</span>{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- inner page banner END --> */}
      </div>
    </div>
  );
};

export default Contact;
